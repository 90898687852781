"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangePasswordWithoutConfirmContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_toastify_1 = require("react-toastify");
const react_hook_form_1 = require("react-hook-form");
const yup_1 = require("@hookform/resolvers/yup");
const messages_1 = require("../../core/i18n/messages");
const useLocale_1 = require("../../hooks/useLocale/useLocale");
const useMutation_1 = require("../../hooks/useMutation/useMutation");
const useModalState_1 = require("../../hooks/useModalState/useModalState");
const ChangePasswordWithoutConfirm_validation_1 = require("./ChangePasswordWithoutConfirm.validation");
const ChangePasswordWithoutConfirm_1 = require("./ChangePasswordWithoutConfirm");
const ChangePasswordWithoutConfirmContainer = ({ changePasswordMutation, selectedUser, }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const { isModalOpen, onModalOpen, onModalClose } = (0, useModalState_1.useModalState)();
    const { mutate: onChangeUserPassword, isLoading } = (0, useMutation_1.useMutation)(changePasswordMutation, {
        onSuccess: () => {
            react_toastify_1.toast.dark(formatMessage({ id: messages_1.AppMessages['changePassword.success'] }));
            onModalClose();
        },
    });
    const { schema } = (0, ChangePasswordWithoutConfirm_validation_1.useChangePasswordWithoutConfirmValidation)();
    const formMethods = (0, react_hook_form_1.useForm)({
        resolver: (0, yup_1.yupResolver)(schema),
        mode: 'all',
    });
    const onSubmit = (body) => {
        if (typeof selectedUser === 'object' && 'username' in selectedUser) {
            onChangeUserPassword({ username: selectedUser.username, body });
            return;
        }
        if (typeof selectedUser === 'object' && 'email' in selectedUser) {
            onChangeUserPassword({ username: selectedUser.email, body });
            return;
        }
    };
    (0, react_1.useEffect)(() => {
        formMethods.reset();
    }, [isModalOpen]);
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, { ...formMethods, children: (0, jsx_runtime_1.jsx)(ChangePasswordWithoutConfirm_1.ChangePasswordWithoutConfirm, { isModalOpen: isModalOpen, onModalClose: onModalClose, onModalOpen: onModalOpen, isLoading: isLoading, onSubmit: onSubmit }) }));
};
exports.ChangePasswordWithoutConfirmContainer = ChangePasswordWithoutConfirmContainer;
