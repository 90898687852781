"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToggleValueInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const EyeIcon_1 = require("../../assets/icons/EyeIcon");
const ClosedEyeIcon_1 = require("../../assets/icons/ClosedEyeIcon");
const Styled = __importStar(require("./ToggleValueInput.styles"));
exports.ToggleValueInput = (0, react_1.forwardRef)(({ label, withoutHelperText, errorMessage, placeholder, required, withoutToggleShow, withoutLabel, ...props }, ref) => {
    const [showValue, setShowValue] = (0, react_1.useState)(false);
    const toggleShowValue = () => setShowValue((prevShowValue) => !prevShowValue);
    return ((0, jsx_runtime_1.jsxs)(Styled.ToggleValueInputWrapper, { children: [label && ((0, jsx_runtime_1.jsxs)(Styled.Label, { htmlFor: props.name, children: [label, " ", required && (0, jsx_runtime_1.jsx)(Styled.Required, { children: "*" })] })), (0, jsx_runtime_1.jsx)(Styled.ToggleValueInput, { ...props, id: props.id || props.name, value: props.value || null, fullWidth: true, "$isError": !!errorMessage, "$isValue": !!props.value, "$isClickable": !props.disabled, inputRef: ref, type: showValue ? 'text' : 'password', label: withoutLabel ? undefined : props.value && !errorMessage ? undefined : placeholder, variant: "filled", size: "small", inputProps: {
                    value: props.value || '',
                    autoComplete: 'new-password',
                    form: {
                        autoComplete: 'off',
                    },
                    ...props.inputProps,
                    ...(props.onKeyDown && { onKeyDown: props.onKeyDown }),
                }, InputProps: {
                    endAdornment: !withoutToggleShow && ((0, jsx_runtime_1.jsx)(Styled.VisibilityIcon, { onClick: toggleShowValue, onMouseDown: toggleShowValue, size: "large", children: showValue ? (0, jsx_runtime_1.jsx)(EyeIcon_1.EyeIcon, {}) : (0, jsx_runtime_1.jsx)(ClosedEyeIcon_1.ClosedEyeIcon, {}) })),
                    disableUnderline: true,
                } }), !withoutHelperText && (0, jsx_runtime_1.jsx)(Styled.ErrorMessage, { children: errorMessage ? errorMessage : ' ' })] }));
});
