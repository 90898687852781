import { AuthContextController, useMutation } from 'shared-ui';
import { PropsWithChildren } from 'react';

import { onLogin, onLogout } from 'api/auth/auth';
import { fetchLoggedUserDetails } from 'api/users/users';
import { QueryKeyEnum } from 'core/global.enum';
import { useUnfinishedWorkModal } from 'hooks/useUnfinishedWorkModal/useUnfinishedWorkModal';
import { useUpdatePasswordModal } from 'hooks/useUpdatePasswordModal/useUpdatePasswordModal';

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { mutateAsync } = useMutation(onLogout);
  const { setIsUnfinishedWorkModalOpen, setUnfinishedWorkId } = useUnfinishedWorkModal();
  const { setIsUpdatePasswordModalOpen } = useUpdatePasswordModal();

  return (
    <AuthContextController
      onLogout={mutateAsync}
      onLogin={onLogin}
      onLoginSuccess={(res) => {
        if (res.hasUnfinishedWorkNotification) {
          setIsUnfinishedWorkModalOpen(true);
          setUnfinishedWorkId(res.unfinishedWorkId);
        }
        if (res.isUpdatePasswordRequired) {
          setIsUpdatePasswordModalOpen(true);
        }
      }}
      fetchUser={fetchLoggedUserDetails}
      fetchUserKey={QueryKeyEnum.LOGGED_USER_DETAILS}
    >
      {children}
    </AuthContextController>
  );
};
