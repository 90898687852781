"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pagination = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const useLocale_1 = require("../../hooks/useLocale/useLocale");
const messages_1 = require("../../core/i18n/messages");
const PaginationSelectIcon_1 = require("../../assets/icons/PaginationSelectIcon");
const Navigation_1 = require("./navigation/Navigation");
const Styled = __importStar(require("./Pagination.styles"));
const Pagination = ({ setPage, setRowsPerPage, rowsPerPageOptions = [25, 50, 100], withoutNavigation, ...rest }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const handleRowChange = (e) => {
        setPage(0);
        setRowsPerPage(parseInt(e.target.value, 10));
    };
    return ((0, jsx_runtime_1.jsx)(Styled.Pagination, { onPageChange: (_, newPage) => setPage(newPage), ActionsComponent: (props) => (0, jsx_runtime_1.jsx)(Navigation_1.Navigation, { ...props, withoutNavigation: withoutNavigation }), labelRowsPerPage: null, rowsPerPageOptions: rowsPerPageOptions, onRowsPerPageChange: handleRowChange, labelDisplayedRows: ({ count }) => `${formatMessage({ id: messages_1.AppMessages['pagination.of'] })} ${count}`, SelectProps: {
            IconComponent: PaginationSelectIcon_1.PaginationSelectIcon,
            MenuProps: {
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                },
            },
        }, ...rest }));
};
exports.Pagination = Pagination;
