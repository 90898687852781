"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckboxIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const CheckboxIcon = ({ type }) => {
    if (type === 'notActive') {
        return ((0, jsx_runtime_1.jsx)("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("rect", { x: "1", y: "1", width: "16", height: "16", rx: "4", stroke: "#495171" }) }));
    }
    else if (type === 'active') {
        return ((0, jsx_runtime_1.jsxs)("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [(0, jsx_runtime_1.jsx)("rect", { x: "1", y: "1", width: "16", height: "16", rx: "4", fill: "#495171", stroke: "#495171" }), (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.70167 9.26705L7.00401 10.542C7.39283 10.9602 8.02547 10.9602 8.41504 10.5412L13.2983 5.31339C13.6882 4.89491 14.3196 4.89566 14.7086 5.31494C15.0977 5.73428 15.097 6.4134 14.7072 6.83183L9.82457 12.0589C8.65811 13.3136 6.76013 13.3136 5.59442 12.0597L4.29284 10.7855C4.09763 10.576 4 10.3012 4 10.0263C4 9.75219 4.09713 9.47798 4.29139 9.2686C4.68041 8.84932 5.31181 8.84862 5.70167 9.26705Z", fill: "#F2F3FA" })] }));
    }
    else if (type === 'activeLight') {
        return ((0, jsx_runtime_1.jsxs)("svg", { width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [(0, jsx_runtime_1.jsx)("rect", { x: "1", y: "1", width: "16", height: "16", rx: "4", fill: "#2d6ce6", stroke: "#2d6ce6" }), (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M5.70167 9.26705L7.00401 10.542C7.39283 10.9602 8.02547 10.9602 8.41504 10.5412L13.2983 5.31339C13.6882 4.89491 14.3196 4.89566 14.7086 5.31494C15.0977 5.73428 15.097 6.4134 14.7072 6.83183L9.82457 12.0589C8.65811 13.3136 6.76013 13.3136 5.59442 12.0597L4.29284 10.7855C4.09763 10.576 4 10.3012 4 10.0263C4 9.75219 4.09713 9.47798 4.29139 9.2686C4.68041 8.84932 5.31181 8.84862 5.70167 9.26705Z", fill: "#F2F3FA" })] }));
    }
};
exports.CheckboxIcon = CheckboxIcon;
