"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Input = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const material_1 = require("@mui/material");
const global_variables_1 = require("../../core/global.variables");
const Styled = __importStar(require("./Input.styles"));
exports.Input = (0, react_1.forwardRef)(({ label, placeholder, errorMessage, helperText, withoutHelperText, required, rightIcon, isClickable, InputProps, variant = 'filled', smallPlaceholderFontSize, withoutErrorMessage, testId, ...props }, ref) => {
    return ((0, jsx_runtime_1.jsxs)("div", { children: [label && ((0, jsx_runtime_1.jsxs)(Styled.Label, { children: [label, " ", required && (0, jsx_runtime_1.jsx)(Styled.Required, { children: "*" })] })), (0, jsx_runtime_1.jsx)(Styled.Input, { inputRef: ref, inputProps: { ...props?.inputProps, 'data-testId': testId }, fullWidth: true, label: props.value && !errorMessage ? undefined : placeholder, variant: variant, size: "small", InputProps: {
                    ...InputProps,
                    endAdornment: rightIcon && (0, jsx_runtime_1.jsx)(material_1.InputAdornment, { position: "end", children: rightIcon }),
                }, InputLabelProps: {
                    shrink: variant !== 'outlined' ? undefined : false,
                }, onKeyDown: (e) => {
                    const isShortcutUsed = e.ctrlKey || e.nativeEvent.metaKey;
                    const allowedKeys = ['Delete', 'Backspace', 'End', 'Home', 'Tab', 'ArrowRight', 'ArrowLeft'];
                    if (props.type === 'number' &&
                        !allowedKeys.includes(e.key) &&
                        !isShortcutUsed &&
                        !global_variables_1.NUMBER_REGEX.test(e.key)) {
                        e.preventDefault();
                        return;
                    }
                    props.onKeyDown && props.onKeyDown(e);
                }, ...props, "$isError": !!errorMessage, "$isValue": !!props.value, "$isClickable": !!isClickable, "$smallPlaceholderFontSize": !!smallPlaceholderFontSize }), !withoutHelperText && ((0, jsx_runtime_1.jsx)(Styled.FormHelperText, { "$isError": !!errorMessage, "$isHelperText": !!helperText, children: withoutErrorMessage ? ' ' : errorMessage ? errorMessage : helperText ? helperText : ' ' }))] }));
});
