"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthContextController = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_1 = require("react-router");
const react_toastify_1 = require("react-toastify");
const AuthContext_1 = require("../authContext/AuthContext");
const useMutation_1 = require("../../../hooks/useMutation/useMutation");
const useLocale_1 = require("../../../hooks/useLocale/useLocale");
const isServerError_1 = require("../../../helpers/isServerError");
const useQuery_1 = require("../../../hooks/useQuery/useQuery");
const useLoaderToast_1 = require("../../../hooks/useLoaderToast/useLoaderToast");
const messages_1 = require("../../../core/i18n/messages");
const useClient_1 = require("../../../hooks/useClient/useClient");
const AuthStorage_1 = require("../../../context/client/auth/authStorage/AuthStorage");
const Styled = __importStar(require("./AuthContextController.styles"));
const AuthContextController = ({ children, onLogin, onLoginSuccess, onLogout, getIsAdmin, fetchUser, fetchUserKey, }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const { load: loadLoginToast, update: updateLoginToast, close: closeLoginToast } = (0, useLoaderToast_1.useLoaderToast)('login');
    const location = (0, react_router_1.useLocation)();
    const [isAuthenticated, setIsAuthenticated] = (0, react_1.useState)(false);
    const { axios: client, setIsSessionExpired } = (0, useClient_1.useClient)();
    const { mutateAsync, isLoading, error } = (0, useMutation_1.useMutation)(onLogin, {
        onMutate: () => {
            loadLoginToast(formatMessage({ id: messages_1.AppMessages['login.loading'] }));
        },
        onSuccess: (res) => {
            if (res.accessToken) {
                client.defaults.headers.common['Authorization'] = `Bearer ${res.accessToken.token}`;
                AuthStorage_1.authStorage.accessToken = res.accessToken.token;
                AuthStorage_1.authStorage.expires = res.accessToken.expiresIn;
            }
            onLoginSuccess && onLoginSuccess(res);
            setIsAuthenticated(true);
            closeLoginToast();
        },
        onError: (error) => {
            setIsAuthenticated(false);
            if ((0, isServerError_1.isServerError)(error.response?.status)) {
                updateLoginToast((0, jsx_runtime_1.jsx)(Styled.ErrorMessage, { children: formatMessage({ id: messages_1.AppMessages['login.error.failed'] }) }));
                return;
            }
            closeLoginToast();
        },
    });
    const { data: user, isLoading: isUserLoading } = (0, useQuery_1.useQuery)([fetchUserKey], fetchUser, {
        enabled: isAuthenticated,
    });
    const isAdmin = getIsAdmin ? getIsAdmin(user) : false;
    (0, react_1.useEffect)(() => {
        setIsAuthenticated(!!AuthStorage_1.authStorage.accessToken);
    }, [location]);
    (0, react_1.useEffect)(() => {
        setIsAuthenticated(!!AuthStorage_1.authStorage.accessToken);
    }, []);
    const login = async (params) => {
        try {
            await mutateAsync(params);
        }
        catch {
            return false;
        }
    };
    const clearClientData = () => {
        client.defaults.headers.common['Authorization'] = undefined;
        AuthStorage_1.authStorage.accessToken = null;
        AuthStorage_1.authStorage.expires = null;
        setIsAuthenticated(false);
        setIsSessionExpired(false);
        react_toastify_1.toast.dismiss();
    };
    const logout = () => {
        if (onLogout) {
            onLogout()
                .catch(() => {
                formatMessage({ id: messages_1.AppMessages['logout.error.failed'] });
            })
                .finally(clearClientData);
        }
        else {
            clearClientData();
        }
    };
    const contextValue = (0, react_1.useMemo)(() => ({
        isAuthenticated,
        isAuthenticating: isLoading,
        login,
        logout,
        loginError: error,
        user,
        isAdmin,
        isUserLoading: isAuthenticated && isUserLoading,
        clearClientData,
    }), [isAuthenticated, isLoading, login, user, isUserLoading]);
    return (0, jsx_runtime_1.jsx)(AuthContext_1.AuthContext.Provider, { value: contextValue, children: children });
};
exports.AuthContextController = AuthContextController;
