import { CircularProgress, IconButton, Typography } from '@mui/material';
import { MediaQuery, theme } from 'shared-ui';
import styled, { css, keyframes } from 'styled-components';

export const REMOTE_WORK_TIMER_HEIGHT = 88;

export const Container = styled.div(
  () => css`
    background-color: ${theme.palette.common.white};
    height: ${REMOTE_WORK_TIMER_HEIGHT}px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;

    @media (min-width: ${MediaQuery.MEDIUM}px) {
      position: relative;
      margin-top: 16px;
      border-radius: ${theme.shape.borderRadius * 2}px;
    }
  `,
);

export const TimerWrapper = styled.div(
  () => css`
    display: flex;
    align-items: center;
  `,
);

export const ButtonWrapper = styled.div(
  () => css`
    position: relative;
    display: flex;
    background-color: #f3f7fe;
    border-radius: 100%;
    margin-left: 10px;
  `,
);

export const Button = styled(IconButton)(
  () => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${theme.palette.primary.main};
    width: 42px;
    height: 42px;
    user-select: none;
    z-index: 10;
    -moz-user-select: none;
    -webkit-text-select: none;
    -webkit-user-select: none;

    &:hover {
      background-color: ${theme.palette.primary.main};
    }
  `,
);

export const Time = styled(Typography)(
  () => css`
    width: 85px;
  `,
);

export const ProgressLoader = styled(CircularProgress)`
  color: ${theme.palette.primary.light};
`;

export const BlinkingColon = styled.span<{ isRunning: boolean }>(
  ({ isRunning }) => css`
    animation-name: ${isRunning && blink};
    animation-duration: 1s;
    animation-iteration-count: infinite;
  `,
);

const blink = keyframes`
  0%, 100% {opacity: 1;}
  50% {opacity: 0;}
`;
