"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Details = exports.DetailsWrapper = exports.DetailsDrawer = exports.Header = void 0;
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
const detailsDrawerWidth = 400;
exports.Header = (0, styled_components_1.default)(material_1.Typography) `
  margin-bottom: 20px;
`;
exports.DetailsDrawer = styled_components_1.default.div(() => (0, styled_components_1.css) `
    width: 400px;
    height: 100vh;
    position: relative;
  `);
exports.DetailsWrapper = styled_components_1.default.div(({ theme: { palette } }) => (0, styled_components_1.css) `
    background-color: ${palette.common.white};
    width: ${detailsDrawerWidth}px;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
  `);
exports.Details = styled_components_1.default.div `
  padding: 96px 40px 0;
`;
