"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Login = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_hook_form_1 = require("react-hook-form");
const useLocale_1 = require("../../hooks/useLocale/useLocale");
const messages_1 = require("../../core/i18n/messages");
const Styled = __importStar(require("./Login.styles"));
const Login = ({ login, isAuthenticating, appName }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const { handleSubmit, control, formState: { errors }, clearErrors, } = (0, react_hook_form_1.useFormContext)();
    const clearInputsErrors = () => {
        clearErrors('username');
        clearErrors('password');
    };
    return ((0, jsx_runtime_1.jsx)(Styled.Wrapper, { children: (0, jsx_runtime_1.jsxs)(Styled.LoginForm, { onSubmit: handleSubmit(login), children: [appName, (0, jsx_runtime_1.jsx)(Styled.Header, { children: formatMessage({ id: messages_1.AppMessages['login.title'] }) }), (0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { name: "username", control: control, render: ({ field }) => ((0, jsx_runtime_1.jsx)(Styled.Input, { ...field, onChange: (e) => {
                            clearInputsErrors();
                            field.onChange(e);
                        }, "$isError": !!errors.username?.message, errorMessage: errors.username?.message, withoutHelperText: true, placeholder: formatMessage({ id: messages_1.AppMessages['login.name.placeholder'] }) })) }), (0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { name: "password", control: control, render: ({ field }) => ((0, jsx_runtime_1.jsx)(Styled.Input, { ...field, onChange: (e) => {
                            clearInputsErrors();
                            field.onChange(e);
                        }, "$isError": !!errors.password?.message, errorMessage: errors.password?.message, type: "password", placeholder: formatMessage({ id: messages_1.AppMessages['login.password.placeholder'] }) })) }), (0, jsx_runtime_1.jsx)(Styled.Button, { isLoading: isAuthenticating, type: "submit", variant: "contained", children: formatMessage({ id: messages_1.AppMessages['login.button'] }) })] }) }));
};
exports.Login = Login;
