"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Profile = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const LogoIcon_1 = require("../../assets/icons/LogoIcon");
const useLocale_1 = require("../../hooks/useLocale/useLocale");
const messages_1 = require("../../core/i18n/messages");
const TopBar_1 = require("../../components/topBar/TopBar");
const PageLayout_1 = require("../../components/pageLayout/PageLayout");
const ChangePasswordContainer_1 = require("../../components/changePassword/ChangePasswordContainer");
const useWindowUtils_1 = require("../../hooks/useWindowUtils/useWindowUtils");
const Logout_1 = require("./profileActions/logout/Logout");
const Styled = __importStar(require("./Profile.styles"));
const Profile = ({ appVersion, changePasswordMutation }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const { isMobileView } = (0, useWindowUtils_1.useWindowUtils)();
    return ((0, jsx_runtime_1.jsx)(PageLayout_1.PageLayout, { header: formatMessage({ id: messages_1.AppMessages['profile.title'] }), renderContent: () => ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(TopBar_1.TopBar, { gridTemplateColumns: "1fr", children: isMobileView ? ((0, jsx_runtime_1.jsx)(TopBar_1.TopBar.EmptyBarWithLine, {})) : ((0, jsx_runtime_1.jsx)(TopBar_1.TopBar.ButtonsWrapper, { children: (0, jsx_runtime_1.jsx)(Logout_1.Logout, {}) })) }), (0, jsx_runtime_1.jsx)(Styled.PageItemHeader, { variant: isMobileView ? 'h5' : 'h4', children: formatMessage({ id: messages_1.AppMessages['profile.accountMenagment'] }) }), (0, jsx_runtime_1.jsx)(Styled.PageItem, { children: (0, jsx_runtime_1.jsxs)(Styled.ChangePasswordWrapper, { children: [(0, jsx_runtime_1.jsx)(Styled.PasswordInputWrapper, { children: (0, jsx_runtime_1.jsx)(Styled.Input, { label: formatMessage({ id: messages_1.AppMessages['profile.password.label'] }), type: "password", value: "********", withoutHelperText: true, InputProps: {
                                        readOnly: true,
                                    } }) }), (0, jsx_runtime_1.jsx)(Styled.ChangePasswordButtonWrapper, { children: (0, jsx_runtime_1.jsx)(ChangePasswordContainer_1.ChangePasswordContainer, { changePasswordMutation: changePasswordMutation }) })] }) }), (0, jsx_runtime_1.jsx)(Styled.PageItemHeader, { variant: isMobileView ? 'h5' : 'h4', children: formatMessage({ id: messages_1.AppMessages['profile.aboutProgram'] }) }), (0, jsx_runtime_1.jsx)(Styled.PageItem, { "$withSamePadding": true, children: (0, jsx_runtime_1.jsxs)(Styled.DetailsInfo, { children: [(0, jsx_runtime_1.jsxs)(Styled.InfoWrapper, { children: [(0, jsx_runtime_1.jsx)(Styled.InfoLabel, { children: formatMessage({ id: messages_1.AppMessages['profile.version'] }) }), (0, jsx_runtime_1.jsxs)(material_1.Typography, { children: [formatMessage({ id: messages_1.AppMessages['profile.version'] }), " ", appVersion] })] }), (0, jsx_runtime_1.jsxs)(Styled.InfoWrapper, { children: [(0, jsx_runtime_1.jsx)(Styled.InfoLabel, { children: formatMessage({ id: messages_1.AppMessages['profile.producer'] }) }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(Styled.LogoWrapper, { children: (0, jsx_runtime_1.jsx)(LogoIcon_1.LogoIcon, {}) }), (0, jsx_runtime_1.jsx)(material_1.Typography, { children: formatMessage({ id: messages_1.AppMessages['profile.companyName'] }) }), (0, jsx_runtime_1.jsx)(Styled.ProducerLink, { href: "https://hsk.com.pl/", target: "_blank", rel: "noopener", children: formatMessage({ id: messages_1.AppMessages['profile.link'] }) })] })] })] }) })] })) }));
};
exports.Profile = Profile;
