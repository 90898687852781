import { useContext } from 'react';

import { RemoteWorkTimerStateContext } from 'context/remoteWorkTimerState/remoteWorkTimerStateContext/RemoteWorkTimerStateContext';

export const useRemoteWorkTimerState = () => {
  const context = useContext(RemoteWorkTimerStateContext);

  if (context === undefined) {
    throw new Error(
      'RemoteWorkTimerStateContext is unavailable, make sure you are using RemoteWorkTimerStateContextController',
    );
  }

  return context;
};
