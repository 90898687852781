import { ClientError, APIError, MutationFn, UseQueryFn } from 'shared-ui';
import { LoginBody } from 'shared-ui/pages/login/Login.types';

import { LoginResponse, LoginErrorResponse, AppVersionResponse } from './auth.types';

export const onLogin: MutationFn<LoginBody, LoginResponse, ClientError<APIError<LoginErrorResponse>>> = (body) => {
  return {
    endpoint: '/auth/login?type=user',
    method: 'POST',
    body: {
      email: body.username,
      password: body.password,
    },
  };
};

export const getAppVersion: UseQueryFn<void, AppVersionResponse> = () => {
  return {
    endpoint: `/version`,
  };
};

export const onLogout: MutationFn<void, void, ClientError<APIError<unknown>>> = () => {
  return {
    endpoint: '/auth/logout',
    method: 'POST',
  };
};
