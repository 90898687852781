import { Avatar } from '@mui/material';
import { LogoWhiteIcon } from 'shared-ui';

import { OwnerPhotoProps } from './Logo.types';
import * as Styled from './Logo.styles';

const LOGO_WIDTH = 216;
const LOGO_HEIGHT = 48;
const DEFAULT_LOGO_WIDTH = 80;

export const Logo = ({ logo }: OwnerPhotoProps) => {
  if (!logo) {
    return (
      <Styled.DefaultLogoContainer
        style={{
          width: LOGO_WIDTH,
          height: LOGO_HEIGHT,
        }}
      >
        <Styled.DefaultLogoWrapper style={{ width: DEFAULT_LOGO_WIDTH }}>
          <LogoWhiteIcon />
        </Styled.DefaultLogoWrapper>
      </Styled.DefaultLogoContainer>
    );
  }

  return <Avatar src={URL.createObjectURL(logo)} sx={{ width: LOGO_WIDTH, height: LOGO_HEIGHT }} variant="rounded" />;
};
