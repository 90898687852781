import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocale, useMutation, ClientError, APIError, useErrorHandler } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { changeUserPasswordWithNoConfirmation } from 'api/users/users';
import { IS_UPDATE_PASSWORD_REQUIRED_KEY } from '../UpdatePasswordModalContextController';

import { UpdatePasswordForm, UpdatePasswordContainerProps } from './UpdatePassword.types';
import { UpdatePassword } from './UpdatePassword';
import { useUpdatePasswordValidation } from './UpdatePassword.validation';

export const UpdatePasswordContainer = ({ isModalOpen, setIsModalOpen }: UpdatePasswordContainerProps) => {
  const { formatMessage } = useLocale();
  const { handleError } = useErrorHandler();

  useEffect(() => {
    if (isModalOpen) {
      sessionStorage.setItem(IS_UPDATE_PASSWORD_REQUIRED_KEY, 'true');
    }
  }, [isModalOpen]);

  const { mutate: onChangeUserPassword, isLoading } = useMutation(changeUserPasswordWithNoConfirmation, {
    onSuccess: () => {
      toast.dark(formatMessage({ id: AppMessages['updatePassword.success'] }));

      sessionStorage.removeItem(IS_UPDATE_PASSWORD_REQUIRED_KEY);
      setIsModalOpen(false);
    },
    onError: (error: ClientError<APIError>) => {
      handleError({ error, omitAPIErrorMessage: true, omitErrorCodes: [401] });
    },
  });

  const { schema } = useUpdatePasswordValidation();
  const formMethods = useForm<UpdatePasswordForm>({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const onSubmit = (body: UpdatePasswordForm) => {
    onChangeUserPassword({ body });
  };

  useEffect(() => {
    formMethods.reset();
  }, [isModalOpen]);

  return (
    <FormProvider {...formMethods}>
      <UpdatePassword isModalOpen={isModalOpen} isLoading={isLoading} onSubmit={onSubmit} />
    </FormProvider>
  );
};
