import { useLocale } from 'shared-ui';
import * as yup from 'yup';

import { AppMessages } from 'i18n/messages';

export const useUpdatePasswordValidation = () => {
  const { formatMessage } = useLocale();

  const schema = yup.object().shape({
    newPassword: yup
      .string()
      .min(8, formatMessage({ id: AppMessages['updatePassword.validation.length'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
    repeatPassword: yup
      .string()
      .min(8, formatMessage({ id: AppMessages['updatePassword.validation.length'] }))
      .oneOf([yup.ref('newPassword'), null], formatMessage({ id: AppMessages['updatePassword.validation.difference'] }))
      .required(formatMessage({ id: AppMessages['common.validation.required'] })),
  });

  return { schema };
};
