"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderCheckboxWrapper = exports.DropdownIcon = exports.TableHeaderItemText = exports.TableHeaderItemTextMaxWidth = exports.TableHeaderItemSortable = exports.TableHeaderItem = exports.TableRowItem = exports.TableRow = exports.TableHeader = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
const DropdownIcon_1 = require("../../assets/icons/DropdownIcon");
exports.TableHeader = (0, styled_components_1.default)(material_1.List)(({ $gridTemplateColumns }) => (0, styled_components_1.css) `
    display: grid;
    grid-template-columns: ${$gridTemplateColumns};
    padding: 0 6px;
  `);
exports.TableRow = (0, styled_components_1.default)(({ className, ...props }) => ((0, jsx_runtime_1.jsx)(material_1.ListItem, { ...props, classes: { root: className } })))(({ $isActive, $gridTemplateColumns, $disabled, $isRowClickable, theme: { palette, shape } }) => (0, styled_components_1.css) `
    display: grid;
    grid-template-columns: ${$gridTemplateColumns};
    min-height: 48px;
    padding: 0 6px;
    margin-bottom: 8px;
    background-color: ${palette.common.white};
    border-radius: ${shape.borderRadius}px;
    border: 1px solid ${$isActive ? palette.grey[500] : 'transparent'};
    cursor: ${$isRowClickable ? 'pointer' : 'auto'};
    opacity: ${$disabled ? 0.5 : 1};

    & .MuiTypography-root {
      font-weight: 500;
    }
  `);
exports.TableRowItem = (0, styled_components_1.default)(material_1.ListItemText) `
  padding: 0 17px;
  word-break: break-word;
`;
exports.TableHeaderItem = (0, styled_components_1.default)(material_1.ListItem)(({ theme: { palette } }) => (0, styled_components_1.css) `
    text-transform: uppercase;
    color: ${palette.grey[900]};
    border: 1px solid transparent;
    padding: 0 16px;
    font-size: 12px;
  `);
exports.TableHeaderItemSortable = styled_components_1.default.div(({ $isActive, $disabled, theme: { palette, shape } }) => (0, styled_components_1.css) `
    display: grid;
    grid-template-columns: 1fr 20px;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 0 10px;
    margin-left: -10px;
    border: 1px solid ${$isActive ? palette.grey[500] : 'transparent'};
    border-radius: ${shape.borderRadius}px;
    cursor: ${$disabled ? 'not-allowed' : 'pointer'};
    line-height: 120%;
  `);
exports.TableHeaderItemTextMaxWidth = (0, styled_components_1.default)(material_1.Typography) `
  margin-top: 2px;
  max-width: 90px;
`;
exports.TableHeaderItemText = styled_components_1.default.span `
  margin-top: 2px;
`;
exports.DropdownIcon = (0, styled_components_1.default)(DropdownIcon_1.DropdownIcon)(({ $disabled }) => (0, styled_components_1.css) `
    opacity: ${$disabled ? '0.3' : '1'};
  `);
exports.HeaderCheckboxWrapper = styled_components_1.default.span `
  margin-top: 5px;
`;
