"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormModalBody = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const useLocale_1 = require("../../../hooks/useLocale/useLocale");
const Button_1 = require("../../button/Button");
const messages_1 = require("../../../core/i18n/messages");
const Styled = __importStar(require("../Modal.styles"));
const FormModalBody = (props) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    return ((0, jsx_runtime_1.jsx)(Styled.ModalFormContent, { children: (0, jsx_runtime_1.jsxs)("form", { onSubmit: (e) => {
                e.preventDefault();
                e.stopPropagation();
                props.onSubmit();
            }, children: [(0, jsx_runtime_1.jsx)(Styled.ModalBody, { "$size": props.size, children: props.children }), (0, jsx_runtime_1.jsxs)(Styled.ButtonsWrapper, { children: [props.onClose && ((0, jsx_runtime_1.jsx)(Button_1.Button, { disabled: props.cancelButtonDisabled, color: "primary", onClick: props.onClose, size: "small", children: props.cancelButtonText ? props.cancelButtonText : formatMessage({ id: messages_1.AppMessages['common.cancel'] }) })), (0, jsx_runtime_1.jsx)(Styled.SubmitButton, { "$isSingleButton": !props.onClose, type: "submit", variant: "contained", color: "primary", size: "small", disabled: props.submitButtonDisabled, isLoading: props.isLoading, "data-testid": "modal-submit-button", children: props.submitButtonText ? props.submitButtonText : formatMessage({ id: messages_1.AppMessages['common.add'] }) })] })] }) }));
};
exports.FormModalBody = FormModalBody;
