import { format } from 'date-fns';
import { ProblemIconWithTooltip, Table, useLocale, Loader, EmptyList } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { REMOTE_WORK_TIMER_HEIGHT } from '../remoteWorkTimer/RemoteWorkTimer.styles';

import * as Styled from './RemoteWorkTable.styles';
import { RemoteWorkTableProps } from './RemoteWorkTable.types';
import { RemoteWorkFilters } from './remoteWorkFilters/RemoteWorkFilters';

const DATE_FORMAT = 'yyyy / MM / dd';
const TIME_FORMAT = 'HH:mm';
const NO_DATE_PLACEHOLDER = '- - - - / - - / - -';
const NO_TIME_PLACEHOLDER = '- - : - -';

export const RemoteWorkTable = ({
  remoteWorkData,
  remoteWorkDataCount,
  isLoading,
  filters,
  setFilters,
}: RemoteWorkTableProps) => {
  const { formatMessage } = useLocale();

  if (isLoading) {
    return <Loader />;
  }

  if (!remoteWorkData.length && !remoteWorkDataCount) {
    return (
      <EmptyList
        iconType="box"
        mainText={formatMessage({ id: AppMessages['remoteWork.table.noData.main'] })}
        helperText={formatMessage({ id: AppMessages['remoteWork.table.noData.helper'] })}
      />
    );
  }

  return (
    <Table
      gridTemplateColumns="1fr 1fr 50px"
      data={remoteWorkData}
      isLoading={isLoading}
      customTableHeight={(height) => height - REMOTE_WORK_TIMER_HEIGHT}
      renderAdditionalTopElement={() => (
        <Styled.FiltersWrapper>
          <RemoteWorkFilters filters={filters} setFilters={setFilters} />
        </Styled.FiltersWrapper>
      )}
      nothingToShowText={
        <EmptyList
          iconType="loop"
          mainText={formatMessage({ id: AppMessages['remoteWork.table.noResults.main'] })}
          helperText={formatMessage({ id: AppMessages['remoteWork.table.noResults.helper'] })}
          sxContainer={{ marginTop: '20px !important' }}
        />
      }
      renderHeader={() => (
        <>
          <Table.HeaderItem>{formatMessage({ id: AppMessages['remoteWork.table.from'] })}</Table.HeaderItem>
          <Table.HeaderItem>{formatMessage({ id: AppMessages['remoteWork.table.to'] })}</Table.HeaderItem>
        </>
      )}
      renderRow={(item) => (
        <>
          <Table.RowItem>
            <Styled.Date>{format(new Date(item.dataOd), DATE_FORMAT)}</Styled.Date>
            <Styled.Time>{format(new Date(item.dataOd), TIME_FORMAT)}</Styled.Time>
          </Table.RowItem>
          <Table.RowItem>
            <Styled.Date>{item.dataDo ? format(new Date(item.dataDo), DATE_FORMAT) : NO_DATE_PLACEHOLDER}</Styled.Date>
            <Styled.Time>{item.dataDo ? format(new Date(item.dataDo), TIME_FORMAT) : NO_TIME_PLACEHOLDER}</Styled.Time>
          </Table.RowItem>
          <Table.RowItem>
            <ProblemIconWithTooltip isError={!item.dataDo}></ProblemIconWithTooltip>
          </Table.RowItem>
        </>
      )}
      rowTooltip={(item) =>
        !item.dataDo
          ? formatMessage({ id: AppMessages['remoteWork.noRegistration'] })
          : formatMessage({ id: AppMessages['remoteWork.registeredCorrectly'] })
      }
    />
  );
};
