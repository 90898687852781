"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonLoader = exports.Button = void 0;
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
const Loader_1 = require("../loader/Loader");
const pickButtonSize = (size, dimension) => {
    if (dimension === 'width') {
        return {
            small: 130,
            medium: 153,
        }[size];
    }
    else if (dimension === 'height') {
        return {
            small: 36,
            medium: 40,
        }[size];
    }
};
exports.Button = (0, styled_components_1.default)(material_1.Button)(({ $size, theme: { palette } }) => (0, styled_components_1.css) `
    min-width: ${pickButtonSize($size, 'width')}px;
    height: ${pickButtonSize($size, 'height')}px;
    padding: 0px 25px;
    position: relative;

    & .MuiButton-contained.Mui-disabled {
      color: ${palette.common.white};
    }
  `);
exports.ButtonLoader = (0, styled_components_1.default)(Loader_1.Loader)(({ theme: { palette } }) => (0, styled_components_1.css) `
    color: ${palette.common.white};
    margin-top: 8px;
  `);
