"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Navigation = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const useLocale_1 = require("../../../hooks/useLocale/useLocale");
const messages_1 = require("../../../core/i18n/messages");
const PaginationLastLeftIcon_1 = require("../../../assets/icons/PaginationLastLeftIcon");
const PaginationArrowLeftIcon_1 = require("../../../assets/icons/PaginationArrowLeftIcon");
const PaginationArrowRightIcon_1 = require("../../../assets/icons/PaginationArrowRightIcon");
const PaginationLastRightIcon_1 = require("../../../assets/icons/PaginationLastRightIcon");
const Styled = __importStar(require("./Navigation.styles"));
const Navigation = ({ count, page, rowsPerPage, onPageChange, withoutNavigation }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const lastPage = Math.ceil(count / rowsPerPage);
    const isFirstPage = page === 0;
    return ((0, jsx_runtime_1.jsxs)(Styled.Navigation, { withoutNavigation: withoutNavigation, children: [page > 1 && ((0, jsx_runtime_1.jsx)(Styled.ArrowButton, { onClick: (event) => onPageChange(event, 0), children: (0, jsx_runtime_1.jsx)(PaginationLastLeftIcon_1.PaginationLastLeftIcon, {}) })), (0, jsx_runtime_1.jsx)(Styled.ArrowButton, { onClick: (event) => onPageChange(event, page - 1), disabled: isFirstPage, children: (0, jsx_runtime_1.jsx)(PaginationArrowLeftIcon_1.PaginationArrowLeftIcon, {}) }), (0, jsx_runtime_1.jsx)(Styled.PagesCounter, { children: `${formatMessage({ id: messages_1.AppMessages['pagination.page'] })} 
        ${!count ? 0 : page + 1}
        ${formatMessage({ id: messages_1.AppMessages['pagination.of'] })} 
        ${lastPage}` }), (0, jsx_runtime_1.jsx)(Styled.ArrowButton, { onClick: (event) => onPageChange(event, page + 1), disabled: page === lastPage - 1 || lastPage === 1, children: (0, jsx_runtime_1.jsx)(PaginationArrowRightIcon_1.PaginationArrowRightIcon, {}) }), lastPage - page > 2 && ((0, jsx_runtime_1.jsx)(Styled.ArrowButton, { onClick: (event) => onPageChange(event, lastPage - 1), children: (0, jsx_runtime_1.jsx)(PaginationLastRightIcon_1.PaginationLastRightIcon, {}) }))] }));
};
exports.Navigation = Navigation;
