import { useState } from 'react';
import { useQuery } from 'shared-ui';
import { startOfMonth, endOfMonth } from 'date-fns';

import { QueryKeyEnum } from 'core/global.enum';
import { fetchRemoteWork } from 'api/remoteWork/remoteWork';

import { RemoteWorkTable } from './RemoteWorkTable';
import { RemoteWorkTableFilters } from './RemoteWorkTable.types';

export const RemoteWorkTableContainer = () => {
  const [filters, setFilters] = useState<RemoteWorkTableFilters>({
    dataOd: startOfMonth(new Date()),
    dataDo: endOfMonth(new Date()),
  });

  const { data, isLoading } = useQuery([QueryKeyEnum.REMOTE_WORK, filters], fetchRemoteWork, {
    args: filters,
    refetchOnWindowFocus: false,
  });

  return (
    <RemoteWorkTable
      remoteWorkData={data?.data || []}
      remoteWorkDataCount={data?.count || 0}
      isLoading={isLoading}
      filters={filters}
      setFilters={setFilters}
    />
  );
};
