import { useContext } from 'react';

import { WebsocketContext } from 'context/websocket/websocketContext/WebsocketContext';

export const useWebsocket = () => {
  const context = useContext(WebsocketContext);

  if (context === undefined) {
    throw new Error('WebsocketContext is unavailable, make sure you are using WebsocketContextController');
  }

  return context;
};
