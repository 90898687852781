"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
const material_1 = require("@mui/material");
exports.theme = (0, material_1.createTheme)({
    palette: {
        primary: {
            main: '#2D6CE6',
            light: '#abc4f5',
        },
        secondary: {
            main: '#DCDDF3',
        },
        background: {
            default: '#F9F9F9',
            paper: '#071E34',
        },
        info: {
            main: '#8A91A8',
            light: '#989daf',
        },
        text: {
            primary: '#383838',
            secondary: '#242424',
        },
        warning: {
            main: '#FF3D3D',
        },
        success: {
            main: '#06D393',
        },
        error: {
            main: '#ed715d',
            light: '#FEF5F3',
        },
        action: {
            selected: '#495171',
            disabledOpacity: 0.5,
        },
        grey: {
            100: '#f8f8f8',
            200: '#f3f4f9',
            300: '#dee0ea',
            500: '#a4aac4',
            600: '#8288a2',
            700: '#4c4c4c',
            800: '#979797',
            900: '#495171',
            A200: '#f3f4f9',
            A400: '#F4F4F4',
            A700: '#5e7388',
        },
    },
    typography: {
        fontFamily: 'Montserrat',
        h2: {
            fontSize: 33,
            fontWeight: 800,
        },
        h3: {
            fontSize: 26,
            fontWeight: 800,
        },
        h4: {
            fontSize: 22,
            fontWeight: 700,
        },
        h5: {
            fontSize: 18,
            fontWeight: 700,
        },
        h6: {
            fontSize: 16,
            fontWeight: 600,
        },
        button: {
            fontSize: 16,
            fontWeight: 700,
            textTransform: 'initial',
        },
        body1: {
            fontWeight: 500,
            fontSize: 14,
        },
        body2: {
            fontWeight: 500,
            fontSize: 12,
        },
        subtitle2: {
            fontSize: 12,
            color: '#979797',
            fontWeight: 700,
        },
        subtitle1: {
            fontWeight: 500,
            fontSize: 16,
        },
    },
    shape: {
        borderRadius: 8,
    },
});
