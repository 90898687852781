import { LoginPage, RemoteWorkName } from 'shared-ui';
import { useEffect } from 'react';

import { AppRoute } from '../../routing/AppRoute.enum';
import { useUpdatePasswordModal } from 'hooks/useUpdatePasswordModal/useUpdatePasswordModal';
import { useUnfinishedWorkModal } from 'hooks/useUnfinishedWorkModal/useUnfinishedWorkModal';

export const LoginContainer = () => {
  const { setIsUpdatePasswordModalOpen } = useUpdatePasswordModal();
  const { setIsUnfinishedWorkModalOpen } = useUnfinishedWorkModal();

  useEffect(() => {
    setIsUpdatePasswordModalOpen(false);
    setIsUnfinishedWorkModalOpen(false);
  }, []);

  return <LoginPage loginUrl={AppRoute.login} redirectUrl={AppRoute.remoteWork} appName={<RemoteWorkName />} />;
};
