"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logout = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const useLocale_1 = require("../../../../hooks/useLocale/useLocale");
const useAuth_1 = require("../../../../hooks/useAuth/useAuth");
const messages_1 = require("../../../../core/i18n/messages");
const useModalState_1 = require("../../../../hooks/useModalState/useModalState");
const Button_1 = require("../../../../components/button/Button");
const Modal_1 = require("../../../../components/modal/Modal");
const useWindowUtils_1 = require("../../../../hooks/useWindowUtils/useWindowUtils");
const useDrawer_1 = require("../../../../hooks/useDrawer/useDrawer");
const Logout = () => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const { isModalOpen, onModalOpen, onModalClose } = (0, useModalState_1.useModalState)();
    const { logout } = (0, useAuth_1.useAuth)();
    const { isMobileView } = (0, useWindowUtils_1.useWindowUtils)();
    const { setIsDrawerOpen } = (0, useDrawer_1.useDrawer)();
    const onLogout = () => {
        logout();
        setIsDrawerOpen(false);
    };
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(Button_1.Button, { size: isMobileView ? 'small' : 'medium', onClick: onModalOpen, variant: "contained", color: "primary", children: formatMessage({ id: messages_1.AppMessages['profile.logout'] }) }), (0, jsx_runtime_1.jsx)(Modal_1.Modal, { header: formatMessage({ id: messages_1.AppMessages['profile.logout'] }), open: isModalOpen, onClose: onModalClose, type: "form", onSubmit: onLogout, submitButtonText: formatMessage({ id: messages_1.AppMessages['profile.logout'] }), children: (0, jsx_runtime_1.jsx)(material_1.Typography, { variant: isMobileView ? 'h6' : 'h5', children: formatMessage({ id: messages_1.AppMessages['profile.logout.text'] }) }) })] }));
};
exports.Logout = Logout;
