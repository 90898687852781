import { useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import { StartStopIcon, useLocale, useLogoutModal, useQueryClient, useWindowUtils } from 'shared-ui';
import { toast } from 'react-toastify';

import { AppMessages } from 'i18n/messages';
import { useWebsocket } from 'hooks/useWebsocket/useWebsocket';
import { QueryKeyEnum } from 'core/global.enum';
import { Messages } from 'app/core/global.types';
import { useRemoteWorkTimerState } from 'hooks/useRemoteWorkTimerState/useRemoteWorkTimerState';

import * as Styled from './RemoteWorkTimer.styles';

const PROCESS_LOADER_SIZE = 56;
const PROCESS_LOADER_THICKNESS = 6;

const workErrors = {
  DEFAULT: 'remoteWork.errors.default',
  WORK_ALREADY_STARTED: 'remoteWork.errors.start.alreadyStarted',
  START_DEFAULT: 'remoteWork.errors.start.default',
  WORK_AUTO_FINISHED: 'remoteWork.errors.finish.autoFinish',
  FINISH_DEFAULT: 'remoteWork.errors.finish.default',
  WORK_ALREADY_FINISHED: 'remoteWork.errors.finish.alreadyFinished',
} as const;

export const RemoteWorkTimer = () => {
  const { formatMessage } = useLocale();
  const socket = useWebsocket();
  const queryClient = useQueryClient();
  const { setIsLogoutModalOpen, isLogoutModalOpen } = useLogoutModal();
  const { minutes, hours, isRunning, start, reset, progress, setProgress } = useRemoteWorkTimerState();
  const { isDesktopView } = useWindowUtils();
  const autoTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const toastStyles = {
    style: { bottom: isDesktopView ? undefined : Styled.REMOTE_WORK_TIMER_HEIGHT },
  };

  const clearTimer = () => {
    queryClient.invalidateQueries([QueryKeyEnum.REMOTE_WORK]);
    setTimeout(() => reset(undefined, false), 0);
    setProgress(100);
  };

  useEffect(() => {
    socket.connect();

    const onWorkExists = ({ duration, autoFinishTimeout }: { duration: number; autoFinishTimeout: number }) => {
      const stopwatchOffset = new Date(new Date().getTime() + duration);
      reset(stopwatchOffset);

      clearTimeout(autoTimeoutRef.current);
      autoTimeoutRef.current = setTimeout(() => {
        clearTimer();
        toast.dark(formatMessage({ id: AppMessages[workErrors.WORK_AUTO_FINISHED] }), {
          ...toastStyles,
          toastId: workErrors.WORK_AUTO_FINISHED,
        });
      }, autoFinishTimeout);
    };

    const onWorkStarted = ({ autoFinishTimeout }: { autoFinishTimeout: number }) => {
      toast.dark(formatMessage({ id: AppMessages['remoteWork.startWork'] }), toastStyles);

      clearTimeout(autoTimeoutRef.current);
      autoTimeoutRef.current = setTimeout(() => {
        clearTimer();
        toast.dark(formatMessage({ id: AppMessages[workErrors.WORK_AUTO_FINISHED] }), {
          ...toastStyles,
          toastId: workErrors.WORK_AUTO_FINISHED,
        });
      }, autoFinishTimeout);
      start();
    };

    const onWorkFinished = () => {
      clearTimeout(autoTimeoutRef.current);
      clearTimer();
      toast.dark(formatMessage({ id: AppMessages['remoteWork.endWork'] }), toastStyles);
    };

    const onAuthError = () => {
      setIsLogoutModalOpen(true);
    };

    const onWorkError = (data: { code?: string }) => {
      let message: Messages = workErrors.DEFAULT;

      if (typeof data?.code === 'string') {
        message = data.code in workErrors ? workErrors[data.code as keyof typeof workErrors] : workErrors.START_DEFAULT;
      }

      clearTimer();
      toast.dark(formatMessage({ id: AppMessages[message] }), toastStyles);
    };

    const clearSocket = () => {
      socket.off('workStarted', onWorkStarted);
      socket.off('workFinished', onWorkFinished);
      socket.off('authError', onAuthError);
      socket.off('workError', onWorkError);
      socket.off('workExists', onWorkExists);
      socket.offAny();

      clearTimeout(autoTimeoutRef.current);
    };

    if (isLogoutModalOpen) {
      clearSocket();
      return;
    }

    socket.on('workError', onWorkError);
    socket.on('authError', onAuthError);
    socket.on('workStarted', onWorkStarted);
    socket.on('workFinished', onWorkFinished);
    socket.on('workExists', onWorkExists);

    return clearSocket;
  }, [isLogoutModalOpen]);

  const onClickStartStopButton = () => {
    if (!navigator.onLine || !socket.connected)
      return toast.dark(formatMessage({ id: AppMessages['common.error.connection'] }), toastStyles);

    isRunning ? socket.emit('finishWork') : socket.emit('startWork');
  };

  return (
    <Styled.Container>
      <Typography>{formatMessage({ id: AppMessages['remoteWork.time'] })}</Typography>

      <Styled.TimerWrapper>
        <Styled.Time variant="h3">
          {hours <= 9 ? '0' + hours : hours}
          <Styled.BlinkingColon isRunning={isRunning}>:</Styled.BlinkingColon>
          {minutes <= 9 ? '0' + minutes : minutes}
        </Styled.Time>

        <Styled.ButtonWrapper>
          <Styled.Button onClick={onClickStartStopButton}>
            <StartStopIcon isRunning={isRunning} />
          </Styled.Button>

          <Styled.ProgressLoader
            size={PROCESS_LOADER_SIZE}
            thickness={PROCESS_LOADER_THICKNESS}
            variant="determinate"
            value={progress}
          />
        </Styled.ButtonWrapper>
      </Styled.TimerWrapper>
    </Styled.Container>
  );
};
