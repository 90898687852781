"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsOverflow = void 0;
const react_1 = require("react");
const useIsOverflow = (ref, callback, listener) => {
    const [isOverflow, setIsOverflow] = (0, react_1.useState)(false);
    (0, react_1.useLayoutEffect)(() => {
        const { current } = ref;
        const trigger = () => {
            if (!current)
                return;
            const hasOverflow = current.offsetWidth < current.scrollWidth;
            setIsOverflow(hasOverflow);
            if (callback)
                callback(hasOverflow);
        };
        if (current) {
            trigger();
        }
    }, [callback, ref, listener]);
    return isOverflow;
};
exports.useIsOverflow = useIsOverflow;
