"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LoginPage = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_router_1 = require("react-router");
const yup_1 = require("@hookform/resolvers/yup");
const react_hook_form_1 = require("react-hook-form");
const useAuth_1 = require("../../hooks/useAuth/useAuth");
const useLocale_1 = require("../../hooks/useLocale/useLocale");
const messages_1 = require("../../core/i18n/messages");
const isServerError_1 = require("../../helpers/isServerError");
const ErrorBoundary_1 = require("../../components/errorBoundary/ErrorBoundary");
const Login_1 = require("./Login");
const Login_validation_1 = require("./Login.validation");
const LoginContainer = ({ loginUrl, redirectUrl, appName }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const { login, isAuthenticating, isAuthenticated, loginError } = (0, useAuth_1.useAuth)();
    const location = (0, react_router_1.useLocation)();
    const { schema } = (0, Login_validation_1.useLoginValidation)();
    const formMethods = (0, react_hook_form_1.useForm)({
        resolver: (0, yup_1.yupResolver)(schema),
        mode: 'all',
        defaultValues: {
            username: '',
            password: '',
        },
    });
    (0, react_1.useEffect)(() => {
        if (loginError) {
            if ((0, isServerError_1.isServerError)(loginError.response?.status))
                return;
            const errorMessage = formatMessage({
                id: messages_1.AppMessages[loginError.response?.data?.type === 'USER_INACTIVE' ? 'login.error.inactive' : 'login.error.invalid'],
            });
            formMethods.setError('username', {
                message: errorMessage,
            });
            formMethods.setError('password', {
                message: errorMessage,
            });
        }
    }, [loginError]);
    if (isAuthenticated) {
        const redirectLink = !location?.state?.prevPath || location?.state.prevPath === loginUrl ? redirectUrl : null;
        return ((0, jsx_runtime_1.jsx)(react_router_1.Navigate, { to: {
                pathname: redirectLink || location?.state?.destinationLink || redirectUrl,
            }, state: { prevPath: location.pathname, destinationLink: location?.state?.destinationLink } }));
    }
    return ((0, jsx_runtime_1.jsx)(ErrorBoundary_1.ErrorBoundary, { children: (0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, { ...formMethods, children: (0, jsx_runtime_1.jsx)(Login_1.Login, { isAuthenticating: isAuthenticating, login: login, appName: appName }) }) }));
};
exports.LoginPage = LoginContainer;
