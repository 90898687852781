import { Typography } from '@mui/material';
import styled from 'styled-components';
import { Button as ButtonBase } from 'shared-ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 24px;
`;

export const Text = styled(Typography)`
  line-height: 22px;
  font-weight: 700;
  min-width: 220px;
`;

export const Button = styled(ButtonBase)`
  min-width: auto;
`;
