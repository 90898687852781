"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthorizedRoute = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
const useAuth_1 = require("../../../hooks/useAuth/useAuth");
const Loader_1 = require("../../../components/loader/Loader");
const AuthorizedRoute = ({ children, adminRoute, loginUrl, redirectUrl }) => {
    const { isAuthenticated, isAdmin, isUserLoading } = (0, useAuth_1.useAuth)();
    const location = (0, react_router_dom_1.useLocation)();
    if (isUserLoading) {
        return (0, jsx_runtime_1.jsx)(Loader_1.Loader, {});
    }
    if (isAuthenticated) {
        return children;
    }
    if (adminRoute) {
        return isAdmin ? children : (0, jsx_runtime_1.jsx)(react_router_dom_1.Navigate, { to: redirectUrl });
    }
    return ((0, jsx_runtime_1.jsx)(react_router_dom_1.Navigate, { to: {
            pathname: loginUrl,
        }, state: {
            destinationLink: location.pathname,
            prevPath: location?.state?.prevPath,
        } }));
};
exports.AuthorizedRoute = AuthorizedRoute;
