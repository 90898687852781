import { Modal, useLocale, CorruptedFileIcon, useMutation } from 'shared-ui';
import { useEffect } from 'react';

import { AppMessages } from 'i18n/messages';
import { confirmWorkNotification } from 'api/users/users';

import * as Styled from './UnfinishedWorkModal.styles';
import { UnfinishedWorkModalProps } from './UnfinishedWorkModal.types';

export const UnfinishedWorkModal = ({ isOpen, handleModalActionClick, unfinishedWorkId }: UnfinishedWorkModalProps) => {
  const { formatMessage } = useLocale();
  const { mutate: onConfirmWorkNotification } = useMutation(confirmWorkNotification);

  useEffect(() => {
    unfinishedWorkId && onConfirmWorkNotification({ id: unfinishedWorkId });
  }, [unfinishedWorkId]);

  return (
    <Modal type="plain" size="xxsmall" onClose={() => {}} open={isOpen}>
      <Styled.Wrapper>
        <CorruptedFileIcon />
        <Styled.Text variant="subtitle1" align="center">
          {formatMessage({ id: AppMessages['unfinishedWorkModal.content'] })}
        </Styled.Text>
        <Styled.Button variant="contained" onClick={handleModalActionClick}>
          {formatMessage({ id: AppMessages['unfinishedWorkModal.confirm'] })}
        </Styled.Button>
      </Styled.Wrapper>
    </Modal>
  );
};
