import { StrictMode } from 'react';
import { SharedUiProvider } from 'shared-ui';

import { translations } from 'i18n/messages';
import { UnfinishedWorkModalContextController } from 'context/unfinishedWorkModal/unfinishedWorkModalContextController/UnfinishedWorkModalContextController';
import { RemoteWorkTimerStateContextController } from 'context/remoteWorkTimerState/remoteWorkTimerStateContextController/RemoteWorkTimerStateContextController';
import { UpdatePasswordModalContextController } from 'context/updatePasswordModal/updatePasswordModalContextController/UpdatePasswordModalContextController';

import { AppProvidersProps } from './AppProviders.types';
import { AuthProvider } from './auth/AuthProvider';

export const AppProviders = ({ children }: AppProvidersProps) => (
  <StrictMode>
    <SharedUiProvider translations={translations}>
      <UnfinishedWorkModalContextController>
        <UpdatePasswordModalContextController>
          <AuthProvider>
            <RemoteWorkTimerStateContextController>{children}</RemoteWorkTimerStateContextController>
          </AuthProvider>
        </UpdatePasswordModalContextController>
      </UnfinishedWorkModalContextController>
    </SharedUiProvider>
  </StrictMode>
);
