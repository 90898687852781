"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoubleTextElipsisWithTooltip = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const TextEllipsisWithTooltip_1 = require("../TextEllipsisWithTooltip");
const DoubleTextElipsisWithTooltip = ({ fullText, firstLineText, secondLineText, width, }) => {
    const [isFullTextOverflow, setIsFullTextNameOverflow] = (0, react_1.useState)(false);
    const [isFirstLineOverflow, setIsFirstLineOverflow] = (0, react_1.useState)(false);
    const [isSecondLineOverflow, setIsSecondLineOverflow] = (0, react_1.useState)(false);
    const isTooltipEnabled = isFirstLineOverflow || isSecondLineOverflow;
    return isFullTextOverflow ? ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(TextEllipsisWithTooltip_1.TextElipsisWithTooltip, { isTooltipEnabled: isTooltipEnabled, text: firstLineText, tooltipText: fullText, width: width, overFlowCallback: (isOverflow) => setIsFirstLineOverflow(isOverflow) }), (0, jsx_runtime_1.jsx)(TextEllipsisWithTooltip_1.TextElipsisWithTooltip, { isTooltipEnabled: isTooltipEnabled, text: secondLineText, tooltipText: fullText, width: width, overFlowCallback: (isOverflow) => setIsSecondLineOverflow(isOverflow) })] })) : ((0, jsx_runtime_1.jsx)(TextEllipsisWithTooltip_1.TextElipsisWithTooltip, { overFlowCallback: (isOverflow) => setIsFullTextNameOverflow(isOverflow), text: fullText }));
};
exports.DoubleTextElipsisWithTooltip = DoubleTextElipsisWithTooltip;
