"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessagesEnum = void 0;
var ErrorMessagesEnum;
(function (ErrorMessagesEnum) {
    ErrorMessagesEnum["UNEXPECTED_ERROR"] = "error.unexpectedError";
    ErrorMessagesEnum["CONNECTION_FAILURE"] = "error.connectionFailure";
    ErrorMessagesEnum["FORBIDDEN"] = "error.unexpectedError";
    ErrorMessagesEnum["NOT_FOUND"] = "error.notFound";
    ErrorMessagesEnum["TIMEOUT"] = "error.timeout";
    ErrorMessagesEnum["SYSTEM_FAILURE"] = "error.systemFailure";
})(ErrorMessagesEnum || (exports.ErrorMessagesEnum = ErrorMessagesEnum = {}));
