import { useMemo } from 'react';
import { DrawerLayout, MediaQuery, ProfileMobileIcon, RemoteWorkIcon, useAuth, useLocale } from 'shared-ui';
import { useWindowSize } from 'react-use';

import { AppRoute } from 'routing/AppRoute.enum';
import { AppMessages } from 'i18n/messages';
import { LogoContainer } from 'ui/logo/LogoContainer';

export const Navbar = () => {
  const { formatMessage } = useLocale();
  const { width } = useWindowSize();
  const { user } = useAuth();

  const isProfileRouteVisible = width < MediaQuery.MEDIUM;

  const drawerRoutes = [
    {
      icon: <RemoteWorkIcon />,
      activeIcon: <RemoteWorkIcon active />,
      to: AppRoute.remoteWork,
      title: formatMessage({ id: AppMessages['nav.remoteWork'] }),
      isVisible: true,
    },
    {
      icon: <ProfileMobileIcon />,
      activeIcon: <ProfileMobileIcon active />,
      to: AppRoute.profile,
      title: formatMessage({ id: AppMessages['nav.profile'] }),
      isVisible: isProfileRouteVisible,
    },
  ];

  const username = useMemo(() => {
    if (user && typeof user === 'object' && 'email' in user) {
      return user.email as string;
    } else {
      return '';
    }
  }, [user]);

  return (
    <DrawerLayout
      logo={<LogoContainer />}
      drawerRoutes={drawerRoutes}
      profileRoute={{ to: AppRoute.profile, title: username }}
    />
  );
};
