"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.authStorage = void 0;
const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';
const EXPIRES_KEY = 'expires';
class AuthStorage {
    _accessToken = null;
    _refreshToken = null;
    _expires = null;
    constructor() {
        try {
            this.accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
            this.refreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY);
            this.expires = Number(sessionStorage.getItem(EXPIRES_KEY));
        }
        catch (error) {
            this.accessToken = null;
            this.refreshToken = null;
            this.expires = null;
        }
    }
    get accessToken() {
        return this._accessToken;
    }
    set accessToken(value) {
        this._accessToken = value;
        try {
            if (typeof value === 'string') {
                sessionStorage.setItem(ACCESS_TOKEN_KEY, value);
            }
            else {
                sessionStorage.removeItem(ACCESS_TOKEN_KEY);
            }
        }
        catch (error) { }
    }
    get refreshToken() {
        return this._refreshToken;
    }
    set refreshToken(value) {
        this._refreshToken = value;
        try {
            if (typeof value === 'string') {
                sessionStorage.setItem(REFRESH_TOKEN_KEY, value);
            }
            else {
                sessionStorage.removeItem(REFRESH_TOKEN_KEY);
            }
        }
        catch (error) { }
    }
    get expires() {
        return this._expires;
    }
    set expires(value) {
        this._expires = value;
        try {
            if (typeof value === 'number') {
                sessionStorage.setItem(EXPIRES_KEY, value.toString());
            }
            else {
                sessionStorage.removeItem(EXPIRES_KEY);
            }
        }
        catch (error) { }
    }
}
exports.authStorage = new AuthStorage();
