"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveIcon = exports.SearchIconWrapper = exports.InputWrapper = exports.Required = exports.Label = exports.Input = exports.Autocomplete = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
const CloseGrayIcon_1 = require("../../assets/icons/CloseGrayIcon");
const autcompleteSizes = {
    padding: {
        small: '2.5px 8px',
        medium: '5px 8px',
        big: '6px 8px',
    },
};
const selectListElementStyles = (0, styled_components_1.css)(({ theme: { palette } }) => (0, styled_components_1.css) `
    color: ${palette.primary.main};
    background-color: ${palette.grey.A200};
  `);
exports.Autocomplete = (0, styled_components_1.default)(({ className, ...props }) => ((0, jsx_runtime_1.jsx)(material_1.Autocomplete, { classes: {
        root: 'root',
        paper: className,
        option: 'option',
        listbox: 'listbox',
        clearIndicator: 'clearIcon',
    }, ...props })))(({ theme: { palette, shape }, $resetNoOptionsWrapper }) => (0, styled_components_1.css) `
    background-color: ${palette.grey.A200};
    box-shadow: none;
    border-radius: ${shape.borderRadius}px;
    border: 1px solid ${palette.grey[500]};

    & .MuiAutocomplete-noOptions {
      ${$resetNoOptionsWrapper &&
    (0, styled_components_1.css) `
        padding: 0;
        margin: 0;
      `}
    }

    & .listbox {
      padding: 0px;
    }

    & .option {
      color: ${palette.grey[900]};
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start !important;
      border-bottom: 1px solid ${palette.grey[500]};
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      padding: 8px !important;
      background-color: ${palette.grey.A200};

      &:last-child {
        border-bottom: none;
      }

      &:hover {
        color: ${palette.primary.main};
        background-color: ${palette.grey[300]};
      }

      &[aria-selected='true'] {
        color: ${palette.primary.main};
        background-color: ${palette.grey.A200};
      }

      &:focus {
        ${selectListElementStyles};
      }
      &:active {
        ${selectListElementStyles};
      }
    }

    & .clearIcon {
      visibility: visible;
      margin-right: 2px;
    }
  `);
exports.Input = (0, styled_components_1.default)(({ className, ...props }) => ((0, jsx_runtime_1.jsx)(material_1.TextField, { ...props, classes: { root: className } })))(({ theme: { palette, shape }, $inputSize, $stopRotatePopupIndicator, $withSearchIcon, $withCustomLabel }) => (0, styled_components_1.css) `
    & .MuiFilledInput-root {
      background-color: ${palette.grey[100]};
      color: ${palette.text.primary};
      border-radius: ${shape.borderRadius}px;
      font-weight: 700;
      text-align: center;
      padding: ${autcompleteSizes.padding[$inputSize || 'medium']} !important;

      ${$withSearchIcon &&
    (0, styled_components_1.css) `
        padding-left: 42px !important;
      `}

      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }

    & .MuiAutocomplete-popupIndicator {
      right: 6px;
    }

    & .MuiAutocomplete-popupIndicatorOpen {
      right: 6px;
    }

    & .clearIcon {
      visibility: visible;
      margin-top: -1px;
    }

    & .MuiOutlinedInput-root {
      padding: ${autcompleteSizes.padding[$inputSize || 'medium']} !important;
    }

    & ::placeholder {
      color: ${palette.text.primary};
      font-weight: 500;
      opacity: 1;
    }

    & .MuiAutocomplete-popupIndicatorOpen {
      transform: ${$stopRotatePopupIndicator ? 'rotate(0)' : 'rotate(180)'};
    }

    ${$withCustomLabel &&
    (0, styled_components_1.css) `
      & .MuiFilledInput-root {
        min-height: 44px;
      }
      &.MuiTextField-root {
        & > label {
          margin-bottom: 2px;
          top: -4px;

          left: ${$withSearchIcon ? '33px' : '0px'};
        }

        & > label.Mui-focused {
          color: inherit;
        }

        & > div > input {
          padding: 10px 4px 0 !important;
        }
      }
    `}
  `);
exports.Label = (0, styled_components_1.default)(material_1.InputLabel) `
  margin-bottom: 8px;
  font-weight: 700;
`;
exports.Required = styled_components_1.default.span(({ theme: { palette } }) => (0, styled_components_1.css) `
    color: ${palette.error.main};
  `);
exports.InputWrapper = styled_components_1.default.div `
  position: relative;
`;
exports.SearchIconWrapper = styled_components_1.default.div `
  margin-top: 2px;
  padding: 0 12px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 100;
`;
exports.RemoveIcon = (0, styled_components_1.default)(CloseGrayIcon_1.CloseGrayIcon) `
  width: 21px;
  height: 21px;
`;
