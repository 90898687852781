import { io } from 'socket.io-client';
import { useEffect } from 'react';
import { authStorage } from 'shared-ui';

import { WebsocketContext } from '../websocketContext/WebsocketContext';

import { WebsocketContextControllerProps } from './WebsocketContextController.types';

const baseUrl = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL || '' : 'http://localhost';
const path = process.env.NODE_ENV === 'production' ? '/socket.io' : '/praca-zdalna/api/prod/socket.io';

const socket = io(baseUrl, {
  path,
  transports: ['websocket'],
  autoConnect: false,
});

export const WebsocketContextController = ({ children }: WebsocketContextControllerProps) => {
  useEffect(() => {
    socket.auth = { token: `Bearer ${authStorage.accessToken}` };
    socket.disconnect().connect();
  }, []);

  return <WebsocketContext.Provider value={socket}>{children}</WebsocketContext.Provider>;
};
