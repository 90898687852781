"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobalStyle = void 0;
const styled_components_1 = require("styled-components");
exports.GlobalStyle = (0, styled_components_1.createGlobalStyle)(({ theme: { palette, shape } }) => (0, styled_components_1.css) `
    ::-webkit-scrollbar {
      display: block;
      width: 7px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${palette.grey[300]};
      border-radius: ${shape.borderRadius}px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${palette.grey[500]};
    }
  `);
