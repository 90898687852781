import { createBrowserRouter } from 'react-router-dom';
import { AuthorizedRoute, LogoutModalContextController } from 'shared-ui';

import { LoginContainer } from '../app/login/LoginContainer';
import { Navbar } from '../app/navbar/Navbar';
import { App } from '../app/App';
import { RemoteWork } from '../app/remoteWork/RemoteWork';
import { ProfileContainer } from '../app/profile/ProfileContainer';

import { AppRoute } from './AppRoute.enum';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        index: true,
        element: <LoginContainer />,
      },
      {
        path: '*',
        element: <LoginContainer />,
      },
      {
        path: 'login',
        element: <LoginContainer />,
      },
      {
        path: 'main/',
        element: (
          <LogoutModalContextController>
            <AuthorizedRoute loginUrl={AppRoute.login} redirectUrl={AppRoute.profile}>
              <Navbar />
            </AuthorizedRoute>
          </LogoutModalContextController>
        ),
        children: [
          {
            path: AppRoute.remoteWork,
            element: <RemoteWork />,
          },
          {
            path: AppRoute.profile,
            element: <ProfileContainer />,
          },
        ],
      },
    ],
  },
]);
