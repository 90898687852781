"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangePassword = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_hook_form_1 = require("react-hook-form");
const useLocale_1 = require("../../hooks/useLocale/useLocale");
const messages_1 = require("../../core/i18n/messages");
const Modal_1 = require("../modal/Modal");
const ToggleValueInput_1 = require("../toggleValueInput/ToggleValueInput");
const Button_1 = require("../button/Button");
const Styled = __importStar(require("./ChangePassword.styles"));
const ChangePassword = ({ onSubmit, onModalClose, onModalOpen, isModalOpen, isLoading, }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const { handleSubmit, control, formState: { errors, isValid }, } = (0, react_hook_form_1.useFormContext)();
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(Button_1.Button, { variant: "contained", color: "primary", onClick: onModalOpen, children: formatMessage({ id: messages_1.AppMessages['changePassword'] }) }), (0, jsx_runtime_1.jsx)(Modal_1.Modal, { header: formatMessage({ id: messages_1.AppMessages['changePassword.title'] }), open: isModalOpen, onClose: onModalClose, size: "medium", type: "form", onSubmit: handleSubmit(onSubmit), submitButtonDisabled: isLoading || !isValid, submitButtonText: formatMessage({ id: messages_1.AppMessages['changePassword.actionButton'] }), children: (0, jsx_runtime_1.jsxs)(Styled.FormWrapper, { children: [(0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { name: "oldPassword", defaultValue: "", control: control, render: ({ field }) => ((0, jsx_runtime_1.jsx)(ToggleValueInput_1.ToggleValueInput, { ...field, errorMessage: errors.oldPassword?.message, type: "password", label: formatMessage({ id: messages_1.AppMessages['changePassword.oldPassword.label'] }), placeholder: formatMessage({
                                    id: messages_1.AppMessages['changePassword.oldPassword.placeholder'],
                                }), required: true })) }), (0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { name: "newPassword", defaultValue: "", control: control, render: ({ field }) => ((0, jsx_runtime_1.jsx)(ToggleValueInput_1.ToggleValueInput, { ...field, errorMessage: errors.newPassword?.message, type: "password", label: formatMessage({ id: messages_1.AppMessages['changePassword.password.label'] }), placeholder: formatMessage({
                                    id: messages_1.AppMessages['changePassword.password.placeholder'],
                                }), required: true })) }), (0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { name: "repeatPassword", defaultValue: "", control: control, render: ({ field }) => ((0, jsx_runtime_1.jsx)(ToggleValueInput_1.ToggleValueInput, { ...field, errorMessage: errors.repeatPassword?.message, type: "password", label: formatMessage({ id: messages_1.AppMessages['changePassword.confirmPassword.label'] }), placeholder: formatMessage({
                                    id: messages_1.AppMessages['changePassword.confirmPassword.placeholder'],
                                }), required: true })) })] }) })] }));
};
exports.ChangePassword = ChangePassword;
