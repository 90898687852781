import { useQuery } from 'shared-ui';

import { fetchLogo } from 'api/logo/logo';
import { QueryKeyEnum } from 'core/global.enum';

import { Logo } from './Logo';

export const LogoContainer = () => {
  const { data: logo } = useQuery([QueryKeyEnum.LOGO], fetchLogo, { handleErrorManually: true });

  return <Logo logo={logo} />;
};
