"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useClient = void 0;
const react_1 = require("react");
const ClientContext_1 = require("../../context/client/clientContext/ClientContext");
const useClient = () => {
    const context = (0, react_1.useContext)(ClientContext_1.ClientContext);
    if (context === undefined) {
        throw new Error('AuthContext must be within AuthProvider');
    }
    return context;
};
exports.useClient = useClient;
