"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDrawer = void 0;
const react_1 = require("react");
const DrawerContext_1 = require("../../context/drawer/drawerContext/DrawerContext");
const useDrawer = () => {
    const context = (0, react_1.useContext)(DrawerContext_1.DrawerContext);
    if (context === undefined) {
        throw new Error('DrawerContext is unavailable, make sure you are using DrawerContextController');
    }
    return context;
};
exports.useDrawer = useDrawer;
