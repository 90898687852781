"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Autocomplete = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const SearchIcon_1 = require("../../assets/icons/SearchIcon");
const PopupSearchIcon_1 = require("../../assets/icons/PopupSearchIcon");
const PaginationSelectIcon_1 = require("../../assets/icons/PaginationSelectIcon");
const Styled = __importStar(require("./Autocomplete.styles"));
exports.Autocomplete = (0, react_1.forwardRef)(({ label, required, placeholder, inputVariant = 'filled', inputSize, stopRotatePopupIndicator, withSearchIcon, withPopupSearchIcon, resetNoOptionsWrapper, customLabel, disableInputChange, ...props }, ref) => {
    return ((0, jsx_runtime_1.jsxs)("div", { children: [label && ((0, jsx_runtime_1.jsxs)(Styled.Label, { children: [label, " ", required && (0, jsx_runtime_1.jsx)(Styled.Required, { children: "*" })] })), (0, jsx_runtime_1.jsx)(Styled.Autocomplete, { ...props, blurOnSelect: true, clearIcon: (0, jsx_runtime_1.jsx)(Styled.RemoveIcon, {}), popupIcon: withPopupSearchIcon ? ((0, jsx_runtime_1.jsx)(PopupSearchIcon_1.PopupSearchIcon, {})) : props.value?.value ? null : (props.popupIcon || (0, jsx_runtime_1.jsx)(PaginationSelectIcon_1.PaginationSelectIcon, {})), getOptionLabel: (option) => option.label, "$resetNoOptionsWrapper": resetNoOptionsWrapper, renderInput: (renderProps) => ((0, jsx_runtime_1.jsxs)(Styled.InputWrapper, { children: [withSearchIcon && ((0, jsx_runtime_1.jsx)(Styled.SearchIconWrapper, { children: (0, jsx_runtime_1.jsx)(SearchIcon_1.SearchIcon, {}) })), (0, jsx_runtime_1.jsx)(Styled.Input, { ...renderProps, inputRef: ref, fullWidth: true, variant: inputVariant, label: customLabel, placeholder: placeholder, inputProps: {
                                ...renderProps.inputProps,
                                placeholder: placeholder,
                                onChange: disableInputChange ? undefined : renderProps.inputProps.onChange,
                            }, InputProps: { ...renderProps.InputProps }, "$inputSize": inputSize, "$stopRotatePopupIndicator": stopRotatePopupIndicator || withPopupSearchIcon, "$withSearchIcon": withSearchIcon, "$withCustomLabel": !!customLabel })] })) })] }));
});
