import { Button, MediaQuery } from 'shared-ui';
import styled from 'styled-components';

export const FilterButton = styled(Button)`
  padding: 0 6px;
  font-size: 14px;
  height: 32px;

  @media (min-width: ${MediaQuery.MEDIUM}px) {
    padding: 0 16px;
  }
`;
