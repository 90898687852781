import { useContext } from 'react';

import { UpdatePasswordModalContext } from 'context/updatePasswordModal/updatePasswordModalContext/UpdatePasswordModalContext';

export const useUpdatePasswordModal = () => {
  const context = useContext(UpdatePasswordModalContext);

  if (context === undefined) {
    throw new Error(
      'UpdatePasswordModalContext is unavailable, make sure you are using UpdatePasswordModalContextController',
    );
  }

  return context;
};
