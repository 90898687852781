"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogoutWrapper = exports.MobileDrawer = exports.Container = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const DRAWER_HEIGHT = 56;
exports.Container = styled_components_1.default.nav(({ theme: { palette } }) => (0, styled_components_1.css) `
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: ${DRAWER_HEIGHT}px;
    background-color: ${palette.background.paper};
    display: flex;
    align-items: center;
    padding: 0 16px;
    z-index: 101;
  `);
exports.MobileDrawer = styled_components_1.default.nav(({ isDrawerOpen, theme: { palette } }) => (0, styled_components_1.css) `
    background-color: ${palette.background.paper};
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    padding: 12px;
    padding-top: ${DRAWER_HEIGHT + 12}px;
    transform: ${isDrawerOpen ? 'translate(0)' : 'translate(-100%)}'};
    transition: all 0.3s ease-in-out;
  `);
exports.LogoutWrapper = styled_components_1.default.div `
  padding: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
`;
