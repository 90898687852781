"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Loader = exports.LoaderWrapper = exports.TitleWithAddonWrapper = exports.DownloadLink = exports.UploadFileErrorWrapper = exports.FilesValidationText = exports.RemoveFileButtonWrapper = exports.IconButton = exports.UploadTextButton = exports.UploadTextName = exports.UploadText = exports.UploadTextWrapper = exports.FolderIconWrapper = exports.DropzoneWithFile = exports.Dropzone = exports.DropzoneWrapper = exports.DisableDropzone = exports.DropzoneContainer = void 0;
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
const Loader_1 = require("../loader/Loader");
exports.DropzoneContainer = styled_components_1.default.div(({ $isDisabled }) => (0, styled_components_1.css) `
    position: relative;
    ${$isDisabled &&
    (0, styled_components_1.css) `
      opacity: 0.4;
    `}
  `);
exports.DisableDropzone = styled_components_1.default.span(({ $isDisabled }) => (0, styled_components_1.css) `
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    ${$isDisabled &&
    (0, styled_components_1.css) `
      opacity: 0.4;
      cursor: not-allowed;
    `}
  `);
exports.DropzoneWrapper = styled_components_1.default.div `
  margin-top: 24px;
  margin-bottom: 30px;
  position: relative;
`;
const dropzoneBaseStyles = (0, styled_components_1.css) `
  margin-bottom: 5px;
  height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
`;
exports.Dropzone = styled_components_1.default.div(({ $isDragActive, $isError, $isDisabled }) => (0, styled_components_1.css) `
    ${dropzoneBaseStyles};

    background-color: ${$isDragActive ? '#eaf1ff' : $isError ? '#fef5f3' : 'transparent'};
    background-image: ${$isDragActive
    ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%232D6CE6FF' stroke-width='4' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`
    : $isError
        ? undefined
        : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23979797FF' stroke-width='4' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`};

    ${$isDisabled &&
    (0, styled_components_1.css) `
      cursor: not-allowed;
    `}
  `);
exports.DropzoneWithFile = styled_components_1.default.div(({ theme: { palette } }) => (0, styled_components_1.css) `
    ${dropzoneBaseStyles};
    background-color: ${palette.grey[200]};
    justify-content: none;
    padding-left: 9px;
    padding-right: 24px;
  `);
exports.FolderIconWrapper = styled_components_1.default.div(({ theme: { palette } }) => (0, styled_components_1.css) `
    margin-right: 14px;
    background-color: ${palette.common.white};
    border-radius: 16px;
    height: 72px;
    width: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
  `);
exports.UploadTextWrapper = styled_components_1.default.div `
  margin-left: 10px;
`;
exports.UploadText = (0, styled_components_1.default)(material_1.Typography) `
  font-weight: 700;
`;
exports.UploadTextName = (0, styled_components_1.default)(material_1.Typography) `
  font-weight: 700;
  margin-bottom: 3px;
`;
exports.UploadTextButton = (0, styled_components_1.default)(material_1.Typography)(({ theme: { palette }, $isDisabled }) => (0, styled_components_1.css) `
    font-weight: 700;
    color: ${$isDisabled ? palette.grey[800] : palette.primary.main};
    cursor: ${$isDisabled ? 'not-allowed' : 'pointer'};
    text-decoration: underline;
  `);
exports.IconButton = (0, styled_components_1.default)(material_1.IconButton)(({ $isDisabled, theme: { palette } }) => (0, styled_components_1.css) `
    background-color: ${palette.common.white};
    ${$isDisabled &&
    (0, styled_components_1.css) `
      opacity: 0.4;
      cursor: not-allowed;
    `}
  `);
exports.RemoveFileButtonWrapper = styled_components_1.default.div `
  margin-left: auto;
`;
exports.FilesValidationText = (0, styled_components_1.default)(material_1.Typography)(({ theme: { palette } }) => (0, styled_components_1.css) `
    color: ${palette.warning.main};
  `);
exports.UploadFileErrorWrapper = styled_components_1.default.div `
  display: flex;
  justify-content: space-between;
`;
exports.DownloadLink = styled_components_1.default.a(({ theme: { palette } }) => (0, styled_components_1.css) `
    font-size: 12px;
    color: ${palette.warning.main};
    font-weight: 700;
  `);
exports.TitleWithAddonWrapper = styled_components_1.default.div `
  display: flex;
  align-items: center;
  gap: 16px;
`;
exports.LoaderWrapper = styled_components_1.default.div(({ theme: { palette } }) => (0, styled_components_1.css) `
    ${dropzoneBaseStyles};
    posiation: relative;
    background-color: ${palette.grey[200]};
  `);
exports.Loader = (0, styled_components_1.default)(Loader_1.Loader) `
  margin-top: -10px;
`;
