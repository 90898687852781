"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = exports.Input = exports.Header = exports.LoginForm = exports.Wrapper = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const Input_1 = require("../../components/input/Input");
const Button_1 = require("../../components/button/Button");
const mediaQuery_enum_1 = require("../../core/mediaQuery.enum");
exports.Wrapper = styled_components_1.default.div `
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media (min-width: ${mediaQuery_enum_1.MediaQuery.SMALL}px) {
    align-items: center;
  }
`;
exports.LoginForm = styled_components_1.default.form(({ theme: { palette } }) => (0, styled_components_1.css) `
    background-color: ${palette.common.white};
    padding: 16px;
    margin-top: 125px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    @media (min-width: ${mediaQuery_enum_1.MediaQuery.SMALL}px) {
      padding: 106px 126px;
      margin-top: 0px;
    }
  `);
exports.Header = styled_components_1.default.h2 `
  font-size: 22px;
  margin: 24px 0 32px;
`;
exports.Input = (0, styled_components_1.default)(Input_1.Input)(({ $isError, theme: { palette } }) => (0, styled_components_1.css) `
    width: 310px;
    min-width: 310px;
    margin: 8px 0 8px;

    @media (min-width: ${mediaQuery_enum_1.MediaQuery.SMALL}px) {
      min-width: 330px;
    }

    & .MuiFilledInput-root {
      background-color: ${$isError ? '#fef5f3' : palette.grey[100]};
    }

    & :-webkit-autofill {
      -webkit-text-fill-color: ${$isError ? palette.error.main : 'inherit'};
      transition: background-color 2147483647s ease-in-out 0s;
    }
  `);
exports.Button = (0, styled_components_1.default)(Button_1.Button) `
  margin-top: 48px;
`;
