"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeLabel = exports.InputWrapper = exports.Input = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
exports.Input = (0, styled_components_1.default)(({ className, ...props }) => ((0, jsx_runtime_1.jsx)(material_1.TextField, { ...props, classes: { root: className } })))(({ theme: { palette, shape }, $isError }) => (0, styled_components_1.css) `
    flex-grow: 1;

    & .MuiFilledInput-root {
      background-color: ${palette.grey[100]};
      color: ${$isError ? palette.error.main : palette.text.primary};
      border-radius: ${shape.borderRadius}px;
      font-weight: 700;
      font-size: 14px;
    }

    & .MuiInputBase-inputSizeSmall {
      padding-top: 12.5px;
      padding-bottom: 12.5px;
    }

    & .MuiFilledInput-root.Mui-focused {
      background-color: ${palette.grey[200]};
    }

    & .MuiFilledInput-underline:before {
      border-bottom: none;
    }

    & .MuiFilledInput-underline:after {
      border-bottom: none;
    }

    & .MuiFormLabel-root {
      color: ${palette.text.primary};
      font-weight: 500;
    }
    & .MuiFilledInput-input:-webkit-autofill {
      border-radius: ${shape.borderRadius}px;
    }
  `);
exports.InputWrapper = styled_components_1.default.div `
  display: flex;
  align-items: center;
  padding: 0 30px;
`;
exports.TimeLabel = (0, styled_components_1.default)(material_1.Typography) `
  font-weight: 700;
  font-size: 16px;
  margin-right: 16px;
`;
