import { MediaQuery, theme } from 'shared-ui';
import styled, { css } from 'styled-components';

export const Line = styled.div(
  () => css`
    background-color: ${theme.palette.grey[300]};
    height: 2px;
    margin-top: 36px;

    @media (min-width: ${MediaQuery.MEDIUM}px) {
      margin-top: 50px;
    }
  `,
);
