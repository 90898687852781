"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProblemIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ProblemIcon = ({ isError }) => {
    if (isError) {
        return ((0, jsx_runtime_1.jsx)("svg", { width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsxs)("g", { fill: "none", fillRule: "evenodd", children: [(0, jsx_runtime_1.jsx)("circle", { fill: "#FDCCC4", cx: "12", cy: "12", r: "12" }), (0, jsx_runtime_1.jsx)("rect", { fill: "#C3432E", x: "10.5", y: "4", width: "3", height: "9", rx: "1.5" }), (0, jsx_runtime_1.jsx)("rect", { fill: "#C3432E", transform: "matrix(1 0 0 -1 0 34)", x: "10.5", y: "15", width: "3", height: "4", rx: "1.5" })] }) }));
    }
    else {
        return ((0, jsx_runtime_1.jsxs)("svg", { width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg", children: [(0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsx)("filter", { id: "y3u3nw5f7a", children: (0, jsx_runtime_1.jsx)("feColorMatrix", { in: "SourceGraphic", values: "0 0 0 0 0.073622 0 0 0 0 0.508977 0 0 0 0 0.408670 0 0 0 1.000000 0" }) }) }), (0, jsx_runtime_1.jsxs)("g", { fill: "none", fillRule: "evenodd", children: [(0, jsx_runtime_1.jsx)("circle", { cx: "12", cy: "12", r: "12", fill: "#C0ECC3" }), (0, jsx_runtime_1.jsx)("g", { filter: "url(#y3u3nw5f7a)", children: (0, jsx_runtime_1.jsx)("path", { d: "m8.702 12.267 1.302 1.275a.95.95 0 0 0 1.411 0l4.883-5.229a.948.948 0 0 1 1.41.002 1.13 1.13 0 0 1 0 1.517l-4.883 5.227c-1.167 1.255-3.065 1.255-4.23 0l-1.302-1.273a1.112 1.112 0 0 1-.293-.76c0-.274.097-.548.291-.757a.948.948 0 0 1 1.41-.002z", fill: "#8288A2" }) })] })] }));
    }
};
exports.ProblemIcon = ProblemIcon;
