"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogoWrapper = exports.ProducerLink = exports.InfoWrapper = exports.DetailsInfo = exports.InfoLabel = exports.Input = exports.PasswordInputWrapper = exports.ChangePasswordButtonWrapper = exports.PageItemHeader = exports.ChangePasswordWrapper = exports.PageItem = void 0;
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
const Input_1 = require("../../components/input/Input");
const mediaQuery_enum_1 = require("../../core/mediaQuery.enum");
exports.PageItem = styled_components_1.default.div(({ theme: { palette, shape }, $withSamePadding }) => (0, styled_components_1.css) `
    background-color: ${palette.common.white};
    padding: ${$withSamePadding ? '16px' : '26px 16px 20px'};
    border-radius: ${shape.borderRadius}px;

    @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
      padding: 24px 40px 28px 28px;
    }
  `);
exports.ChangePasswordWrapper = styled_components_1.default.div `
  @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
    display: flex;
    gap: 32px;
  }
`;
exports.PageItemHeader = (0, styled_components_1.default)(material_1.Typography) `
  margin: 24px 0 20px;

  @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
    margin: 40px 0 16px;
  }
`;
exports.ChangePasswordButtonWrapper = styled_components_1.default.div `
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;

  @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
    margin-top: 30px;
  }
`;
exports.PasswordInputWrapper = styled_components_1.default.div `
  max-width: 330px;
`;
exports.Input = (0, styled_components_1.default)(Input_1.Input) `
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }
`;
exports.InfoLabel = (0, styled_components_1.default)(material_1.Typography) `
  font-weight: 700;
`;
exports.DetailsInfo = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
    display: block;
  }
`;
exports.InfoWrapper = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
    display: grid;
    gap: 0;
    grid-template-columns: 150px 280px;

    &:not(:last-child) {
      margin-bottom: 28px;
    }
  }
`;
exports.ProducerLink = styled_components_1.default.a(({ theme: { palette } }) => (0, styled_components_1.css) `
    display: block;
    font-weight: 700;
    text-decoration: underline;
    margin-top: 5px;
    color: ${palette.text.primary};
  `);
exports.LogoWrapper = styled_components_1.default.div `
  width: 71px;
  margin-bottom: 5px;
`;
