"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLocale = void 0;
const react_1 = require("react");
const react_intl_1 = require("react-intl");
const LocaleContext_1 = require("../../context/locale/localeContext/LocaleContext");
const useLocale = () => {
    const intl = (0, react_intl_1.useIntl)();
    const localeContext = (0, react_1.useContext)(LocaleContext_1.LocaleContext);
    if (localeContext === undefined) {
        throw new Error('LocaleContext is unavailable, make sure you are using LocaleContextController');
    }
    const locale = (0, react_1.useMemo)(() => ({
        ...intl,
        ...localeContext,
    }), [intl, localeContext]);
    return locale;
};
exports.useLocale = useLocale;
