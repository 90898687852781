"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_toastify_1 = require("react-toastify");
const styled_components_1 = __importStar(require("styled-components"));
const global_variables_1 = require("../../core/global.variables");
const mediaQuery_enum_1 = require("../../core/mediaQuery.enum");
exports.ToastContainer = (0, styled_components_1.default)((props) => (0, jsx_runtime_1.jsx)(react_toastify_1.ToastContainer, { ...props, theme: "dark" }))(({ $isDrawerOpen, theme: { shape, palette } }) => (0, styled_components_1.css) `
    &.Toastify__toast-container--bottom-left {
      left: 5px !important;
      bottom: 10px !important;
      transition: left 0.2s ease-out;
      width: auto;

      @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
        left: ${$isDrawerOpen ? global_variables_1.DRAWER_OPEN_WIDTH : global_variables_1.DRAWER_CLOSE_WIDTH}px !important;
      }
    }

    .Toastify__toast {
      border-radius: ${shape.borderRadius}px;
      left: 10px;
      margin-bottom: 0;
      text-align: left;
      transition: left 0.2s ease-out;
      max-width: 300px;
      margin-bottom: 8px;

      @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
        left: 40px;
        margin-bottom: 16px;
        width: max-content;
      }
    }

    .Toastify__toast-body {
      padding: 2px 5px;

      @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
        padding: 6px 20px;
      }
    }

    .Toastify__toast-theme--dark {
      background-color: ${palette.background.paper};
    }
  `);
