import { UseQueryFn, MutationFn } from 'shared-ui';

import { ChangePasswordBody, ChangePasswordWithConfirmationBody } from './users.types';

export const fetchLoggedUserDetails: UseQueryFn<void, void> = () => {
  return {
    endpoint: `/me`,
  };
};

export const changeUserPasswordWithConfirmation: MutationFn<
  { username: string; body: ChangePasswordWithConfirmationBody },
  void
> = ({ username, body }) => {
  return {
    endpoint: `/user/${username}/password`,
    method: 'PUT',
    body,
  };
};

export const confirmWorkNotification: MutationFn<{ id: string }, void> = ({ id }) => {
  return {
    endpoint: `/confirm-work-notification/${id}`,
    method: 'POST',
  };
};

export const changeUserPasswordWithNoConfirmation: MutationFn<{ body: ChangePasswordBody }, void> = ({ body }) => {
  return {
    endpoint: `/user/password/noconfirm`,
    method: 'PUT',
    body,
  };
};
