"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Modal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const Styled = __importStar(require("./Modal.styles"));
const FormModalBody_1 = require("./formModalBody/FormModalBody");
const InfoModalBody_1 = require("./infoModalBody/InfoModalBody");
const ModalHeader_1 = require("./modalHeader/ModalHeader");
const DefaultModalBody_1 = require("./defaultModalBody/DefaultModalBody");
const Modal = ({ size = 'medium', ...props }) => {
    const getModalBody = () => {
        switch (props.type) {
            case 'form':
                return (0, jsx_runtime_1.jsx)(FormModalBody_1.FormModalBody, { ...props, size: size });
            case 'info':
                return (0, jsx_runtime_1.jsx)(InfoModalBody_1.InfoModalBody, { ...props, size: size });
            case 'plain':
                return null;
            default:
                return (0, jsx_runtime_1.jsx)(DefaultModalBody_1.DefaultModalBody, { ...props, size: size });
        }
    };
    return ((0, jsx_runtime_1.jsx)(Styled.Modal, { open: props.open, onClose: props.onClose, slots: {
            backdrop: material_1.Backdrop,
        }, disableScrollLock: true, zIndex: props.zIndex, children: (0, jsx_runtime_1.jsx)(Styled.ModalContainer, { "$size": size, children: props.type === 'plain' ? ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: props.children })) : ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(ModalHeader_1.ModalHeader, { onClose: props.onClose, header: props.header, withBackButton: props.withBackButton, onClickBackButton: props.onClickBackButton }), getModalBody()] })) }) }));
};
exports.Modal = Modal;
