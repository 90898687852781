"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.requestSuccessInterceptor = void 0;
const AuthStorage_1 = require("../../../../context/client/auth/authStorage/AuthStorage");
const requestSuccessInterceptor = (config) => {
    if (AuthStorage_1.authStorage.accessToken) {
        config.headers.Authorization = `Bearer ${AuthStorage_1.authStorage.accessToken}`;
        config.withCredentials = true;
        return config;
    }
    return config;
};
exports.requestSuccessInterceptor = requestSuccessInterceptor;
