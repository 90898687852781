"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabPanel = exports.Tab = exports.Tabs = exports.TabsWrapper = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const lab_1 = require("@mui/lab");
const styled_components_1 = __importStar(require("styled-components"));
exports.TabsWrapper = styled_components_1.default.div `
  margin-bottom: 24px;
`;
exports.Tabs = (0, styled_components_1.default)(material_1.Tabs) `
  min-height: auto;

  div {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  span {
    background-color: transparent;
  }
`;
exports.Tab = (0, styled_components_1.default)(({ className, ...props }) => (0, jsx_runtime_1.jsx)(material_1.Tab, { ...props, classes: { root: className } }))(({ theme: { palette } }) => (0, styled_components_1.css) `
    background-color: ${palette.grey[300]};
    font-weight: 600;
    border-radius: 16px;
    padding: 6px 10px;
    font-size: 14px;
    border: 1px solid ${palette.grey[300]};
    min-height: auto;
    min-width: auto;
    margin-bottom: 6px;
    height: 32px;

    &.Mui-selected {
      background-color: ${palette.common.white};
      border-color: ${palette.primary.main};
      color: ${palette.text.primary};
    }
  `);
exports.TabPanel = (0, styled_components_1.default)(lab_1.TabPanel) `
  padding: 0;
  margin: 0 0 32px;
  overflow: auto;
  position: relative;
  height: 100%;
`;
