"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Dropzone = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const react_dropzone_1 = require("react-dropzone");
const messages_1 = require("../../core/i18n/messages");
const Tooltip_1 = require("../tooltip/Tooltip");
const useLocale_1 = require("../../hooks/useLocale/useLocale");
const UploadIcon_1 = require("../../assets/icons/UploadIcon");
const CloseIcon_1 = require("../../assets/icons/CloseIcon");
const FolderIcon_1 = require("../../assets/icons/FolderIcon");
const useDropzoneHelpers_1 = require("./useDropzoneHelpers/useDropzoneHelpers");
const Styled = __importStar(require("./Dropzone.styles"));
const Dropzone = ({ rootDropzoneProps, title, isLoading, onClickRemoveButton, file, error, downloadLink, dropzoneDescription, addedFileSubtext, uploadIcon, type, titleAddonEnd, removeFileButtonOptions, }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const { getFilesWithResolution, customImageRsolutionValidation, customFileValidation } = (0, useDropzoneHelpers_1.useDropzoneHelpers)();
    const isFileArrayType = Array.isArray(file);
    const getRootDropzoneProps = () => {
        if (type === 'images') {
            return {
                getFilesFromEvent: async (event) => getFilesWithResolution(event),
                validator: (file) => customImageRsolutionValidation(file),
                ...rootDropzoneProps,
            };
        }
        else {
            return {
                validator: (file) => customFileValidation(file),
                ...rootDropzoneProps,
            };
        }
    };
    const { getRootProps, getInputProps, isDragActive } = (0, react_dropzone_1.useDropzone)(getRootDropzoneProps());
    const { disabled } = rootDropzoneProps;
    return ((0, jsx_runtime_1.jsxs)(Styled.DropzoneContainer, { "$isDisabled": !!disabled, children: [disabled && (0, jsx_runtime_1.jsx)(Styled.DisableDropzone, { "$isDisabled": !!disabled }), titleAddonEnd ? ((0, jsx_runtime_1.jsxs)(Styled.TitleWithAddonWrapper, { children: [(0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", children: title }), titleAddonEnd] })) : ((0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "h5", children: title })), (0, jsx_runtime_1.jsxs)(Styled.DropzoneWrapper, { children: [isLoading ? ((0, jsx_runtime_1.jsx)(Styled.LoaderWrapper, { children: (0, jsx_runtime_1.jsx)(Styled.Loader, { size: 30 }) })) : !file || (isFileArrayType && !file.length) ? ((0, jsx_runtime_1.jsx)(Styled.Dropzone, { ...getRootProps(), "$isDragActive": isDragActive, "$isError": !!error, "$isDisabled": disabled, children: (0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("input", { ...getInputProps() }), uploadIcon ? uploadIcon : (0, jsx_runtime_1.jsx)(UploadIcon_1.UploadIcon, {}), (0, jsx_runtime_1.jsxs)(Styled.UploadTextWrapper, { children: [(0, jsx_runtime_1.jsx)(Styled.UploadText, { children: formatMessage({ id: messages_1.AppMessages['common.dropzone.dragFile'] }) }), (0, jsx_runtime_1.jsx)(Styled.UploadTextButton, { "$isDisabled": !!disabled, children: formatMessage({ id: messages_1.AppMessages['common.dropzone.chooseFromDevice'] }) })] })] }) })) : ((0, jsx_runtime_1.jsxs)(Styled.DropzoneWithFile, { children: [(0, jsx_runtime_1.jsx)(Styled.FolderIconWrapper, { children: (0, jsx_runtime_1.jsx)(FolderIcon_1.FolderIcon, {}) }), (0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)(Styled.UploadTextName, { children: isFileArrayType
                                            ? formatMessage({ id: messages_1.AppMessages['common.dropzone.filesNumber'] }, { filesNumber: file.length })
                                            : file.name }), addedFileSubtext && (0, jsx_runtime_1.jsx)(Styled.UploadText, { children: addedFileSubtext })] }), (0, jsx_runtime_1.jsx)(Styled.RemoveFileButtonWrapper, { children: (0, jsx_runtime_1.jsx)(Tooltip_1.Tooltip, { title: !!removeFileButtonOptions?.disabled && removeFileButtonOptions.disabledTooltipText
                                        ? removeFileButtonOptions.disabledTooltipText
                                        : '', children: (0, jsx_runtime_1.jsx)(Styled.IconButton, { "$isDisabled": !!removeFileButtonOptions?.disabled, onClick: () => (removeFileButtonOptions?.disabled ? undefined : onClickRemoveButton(undefined)), children: (0, jsx_runtime_1.jsx)(CloseIcon_1.CloseIcon, {}) }) }) })] })), error ? ((0, jsx_runtime_1.jsxs)(Styled.UploadFileErrorWrapper, { children: [(0, jsx_runtime_1.jsx)(Styled.FilesValidationText, { variant: "subtitle2", children: error }), downloadLink && ((0, jsx_runtime_1.jsx)(Styled.DownloadLink, { download: "report.txt", href: downloadLink, children: formatMessage({ id: messages_1.AppMessages['common.download.errorReport'] }) }))] })) : dropzoneDescription ? ((0, jsx_runtime_1.jsx)(material_1.Typography, { variant: "subtitle2", children: dropzoneDescription })) : null] })] }));
};
exports.Dropzone = Dropzone;
