"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveText = exports.ListItem = exports.List = exports.Popover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
exports.Popover = (0, styled_components_1.default)(({ className, ...props }) => ((0, jsx_runtime_1.jsx)(material_1.Popover, { ...props, classes: { root: className, paper: 'paper' } })))(({ theme: { palette } }) => (0, styled_components_1.css) `
    & .paper {
      background-color: ${palette.grey.A200};
      border: 1px solid ${palette.grey[500]};
      margin-top: 8px;
      min-width: 146px;
      max-height: 350px;
    }
  `);
exports.List = (0, styled_components_1.default)(material_1.List) `
  padding-top: 0px;
  padding-bottom: 0px;
`;
exports.ListItem = (0, styled_components_1.default)(material_1.ListItem)(({ theme: { palette } }) => (0, styled_components_1.css) `
    padding: 16px;
    border-bottom: 1px solid ${palette.grey[500]};
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      color: ${palette.primary.main};
    }
  `);
exports.ActiveText = (0, styled_components_1.default)(material_1.Typography)(({ $active, theme: { palette } }) => (0, styled_components_1.css) `
    color: ${$active ? palette.primary.main : undefined};
  `);
