"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LogoutModalContextController = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const LogoutModalContext_1 = require("../logoutModalContext/LogoutModalContext");
const useAuth_1 = require("../../../hooks/useAuth/useAuth");
const useClient_1 = require("../../../hooks/useClient/useClient");
const LogoutModal_1 = require("../../../components/logoutModal/LogoutModal");
const RemoteWorkName_1 = require("../../../components/remoteWorkName/RemoteWorkName");
const LogoutModalContextController = ({ children }) => {
    const [isLogoutModalOpen, setIsLogoutModalOpen] = (0, react_1.useState)(false);
    const { clearClientData } = (0, useAuth_1.useAuth)();
    const handleModalActionClick = () => {
        clearClientData();
        setIsLogoutModalOpen(false);
    };
    const { isSessionExpired } = (0, useClient_1.useClient)();
    (0, react_1.useEffect)(() => {
        if (isSessionExpired)
            setIsLogoutModalOpen(true);
    }, [isSessionExpired]);
    return ((0, jsx_runtime_1.jsxs)(LogoutModalContext_1.LogoutModalContext.Provider, { value: { setIsLogoutModalOpen, isLogoutModalOpen }, children: [children, (0, jsx_runtime_1.jsx)(LogoutModal_1.LogoutModal, { appName: (0, jsx_runtime_1.jsx)(RemoteWorkName_1.RemoteWorkName, {}), isOpen: isLogoutModalOpen, handleModalActionClick: handleModalActionClick })] }));
};
exports.LogoutModalContextController = LogoutModalContextController;
