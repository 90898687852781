"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HamburgerIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const HamburgerIcon = ({ isOpen }) => {
    if (isOpen) {
        return ((0, jsx_runtime_1.jsx)("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20.366 3.39541C19.8388 2.8682 18.984 2.8682 18.4568 3.39541L11.9114 9.94081L5.39541 3.42484C4.8682 2.89763 4.01343 2.89764 3.48622 3.42484C2.95901 3.95205 2.95901 4.80682 3.48622 5.33403L10.0022 11.85L3.39541 18.4568C2.8682 18.984 2.8682 19.8388 3.39541 20.366C3.92261 20.8932 4.77739 20.8932 5.3046 20.366L11.9114 13.7592L18.5476 20.3954C19.0748 20.9226 19.9296 20.9226 20.4568 20.3954C20.984 19.8682 20.984 19.0134 20.4568 18.4862L13.8206 11.85L20.366 5.30459C20.8932 4.77739 20.8932 3.92261 20.366 3.39541Z", fill: "white" }) }));
    }
    else {
        return ((0, jsx_runtime_1.jsxs)("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: [(0, jsx_runtime_1.jsx)("rect", { x: "2", y: "5", width: "20", height: "2", rx: "1", fill: "white" }), (0, jsx_runtime_1.jsx)("rect", { x: "2", y: "11", width: "20", height: "2", rx: "1", fill: "white" }), (0, jsx_runtime_1.jsx)("rect", { x: "2", y: "17", width: "20", height: "2", rx: "1", fill: "white" })] }));
    }
};
exports.HamburgerIcon = HamburgerIcon;
