"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isJustLetters = void 0;
const global_variables_1 = require("../core/global.variables");
const isJustLetters = (val) => {
    if (!val)
        return true;
    return global_variables_1.JUST_LETTERS_REGEX.test(val);
};
exports.isJustLetters = isJustLetters;
