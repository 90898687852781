"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useObserver = void 0;
const react_1 = require("react");
const ObserverContext_1 = require("../context/observer/observerContext/ObserverContext");
const useObserver = () => {
    const context = (0, react_1.useContext)(ObserverContext_1.ObserverContext);
    if (context === undefined) {
        throw new Error('useObserver must be used within an ObserverContextController');
    }
    return context;
};
exports.useObserver = useObserver;
