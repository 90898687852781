import { MediaQuery } from 'shared-ui';
import styled from 'styled-components';

export const Date = styled.span`
  font-weight: bold;
  margin-right: 10px;
  white-space: nowrap;
`;

export const Time = styled.span`
  white-space: nowrap;
`;

export const FiltersWrapper = styled.div`
  padding: 16px 0;
  display: flex;
  gap: 8px;

  @media (min-width: ${MediaQuery.MEDIUM}px) {
    gap: 16px;
  }
`;
