import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { useStopwatch } from 'react-timer-hook';
import { useAuth } from 'shared-ui';

import { RemoteWorkTimerStateContext } from '../remoteWorkTimerStateContext/RemoteWorkTimerStateContext';
import { User } from 'api/users/users.types';

import { RemoteWorkTimerStateContextControllerProps } from './RemoteWorkTimerStateContextController.types';

export const RemoteWorkTimerStateContextController = ({ children }: RemoteWorkTimerStateContextControllerProps) => {
  const stopwatch = useStopwatch();
  const { user } = useAuth<User, unknown, unknown>();

  const [progress, setProgress] = useState(100);

  useInterval(
    () => {
      setProgress((prevProgress) => prevProgress + 1);
    },
    stopwatch.isRunning ? 100 : null,
  );

  useEffect(() => {
    stopwatch.reset(undefined, false);
    setProgress(100);
  }, [user?.email]);

  return (
    <RemoteWorkTimerStateContext.Provider value={{ progress, setProgress, ...stopwatch }}>
      {children}
    </RemoteWorkTimerStateContext.Provider>
  );
};
