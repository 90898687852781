"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLogoutModal = void 0;
const react_1 = require("react");
const LogoutModalContext_1 = require("../../context/logoutModal/logoutModalContext/LogoutModalContext");
const useLogoutModal = () => {
    const context = (0, react_1.useContext)(LogoutModalContext_1.LogoutModalContext);
    if (context === undefined) {
        throw new Error('LogoutModalContext is unavailable, make sure you are using LogoutModalContextController');
    }
    return context;
};
exports.useLogoutModal = useLogoutModal;
