"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeaderTitleWrapper = exports.ConfirmButton = exports.SubmitButton = exports.ButtonsWrapper = exports.ModalBody = exports.ModalFormContent = exports.ModalContent = exports.CloseIcon = exports.IconButtonClose = exports.HeaderWrapper = exports.ModalContainer = exports.Modal = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
const CloseIcon_1 = require("../../assets/icons/CloseIcon");
const hexWithOpacity_1 = require("../../helpers/hexWithOpacity");
const Button_1 = require("../button/Button");
const mediaQuery_enum_1 = require("../../core/mediaQuery.enum");
const modalSize = {
    xxsmall: 375,
    xsmall: 576,
    small: 620,
    medium: 800,
    big: 1110,
};
exports.Modal = (0, styled_components_1.default)(({ className, ...props }) => ((0, jsx_runtime_1.jsx)(material_1.Modal, { ...props, classes: { root: className }, BackdropProps: {
        classes: {
            root: 'backdrop',
        },
    } })))((props) => (0, styled_components_1.css) `
    z-index: ${props.zIndex || 1300};

    .backdrop {
      background-color: ${(0, hexWithOpacity_1.hexWithOpacity)('#071e34', 0.3)};
    }
  `);
exports.ModalContainer = styled_components_1.default.div(({ $size, theme: { palette, shape } }) => (0, styled_components_1.css) `
    background-color: ${palette.common.white};
    border-radius: ${shape.borderRadius}px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;

    @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
      width: ${modalSize[$size]}px;
    }
  `);
exports.HeaderWrapper = styled_components_1.default.div(({ theme: { palette, shape } }) => (0, styled_components_1.css) `
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 16px;
    border-top-right-radius: ${shape.borderRadius}px;
    border-top-left-radius: ${shape.borderRadius}px;
    background-color: ${palette.grey[100]};

    @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
      padding: 36px 40px;
    }
  `);
exports.IconButtonClose = (0, styled_components_1.default)(material_1.IconButton)(({ theme: { palette } }) => (0, styled_components_1.css) `
    background-color: ${palette.common.white};
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  `);
exports.CloseIcon = (0, styled_components_1.default)(CloseIcon_1.CloseIcon) `
  position: absolute;
  top: 51%;
  left: 49%;
  transform: translate(-50%, -50%);
`;
exports.ModalContent = styled_components_1.default.div `
  padding: 28px 40px 40px;
`;
exports.ModalFormContent = styled_components_1.default.div `
  padding: 0 0 20px;

  @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
    padding: 0 0 40px;
  }
`;
exports.ModalBody = styled_components_1.default.div(({ $size }) => (0, styled_components_1.css) `
    max-height: ${$size === 'medium' ? '65vh' : '60vh'};
    overflow: auto;
    padding: 16px 16px 0;

    @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
      padding: 28px 40px 0;
    }
  `);
exports.ButtonsWrapper = styled_components_1.default.div `
  display: flex;
  gap: 24px;
  padding: 0 16px;
  margin-top: 25px;

  @media (min-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
    justify-content: flex-end;
    margin-top: 40px;
    margin-right: 40px;
    padding: 0;
  }
`;
exports.SubmitButton = (0, styled_components_1.default)(Button_1.Button)(({ $isSingleButton }) => (0, styled_components_1.css) `
    @media (max-width: ${mediaQuery_enum_1.MediaQuery.MEDIUM}px) {
      width: ${$isSingleButton ? '100%' : 'auto'};
    }
  `);
exports.ConfirmButton = (0, styled_components_1.default)(Button_1.Button) `
  margin-bottom: 40px;
`;
exports.HeaderTitleWrapper = styled_components_1.default.div `
  display: flex;
  gap: 10px;
  align-items: center;
`;
