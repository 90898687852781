"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsExactPath = void 0;
const react_router_dom_1 = require("react-router-dom");
const useIsExactPath = (path, end) => {
    const { pathname } = (0, react_router_dom_1.useLocation)();
    const isExactPath = !!(0, react_router_dom_1.matchPath)({ end: end ?? true, path }, pathname);
    return isExactPath;
};
exports.useIsExactPath = useIsExactPath;
