import { useState } from 'react';

import { UnfinishedWorkModalContext } from '../unfinishedWorkModalContext/UnfinishedWorkModalContext';
import { UnfinishedWorkModal } from 'ui/unfinishedWorkModal/UnfinishedWorkModal';

import { UnfinishedWorkModalContextControllerProps } from './UnfinishedWorkModalContextController.types';

export const UnfinishedWorkModalContextController = ({ children }: UnfinishedWorkModalContextControllerProps) => {
  const [isUnfinishedWorkModalOpen, setIsUnfinishedWorkModalOpen] = useState(false);
  const [unfinishedWorkId, setUnfinishedWorkId] = useState<string>();

  const handleModalActionClick = () => {
    setIsUnfinishedWorkModalOpen(false);
  };

  return (
    <UnfinishedWorkModalContext.Provider
      value={{ setIsUnfinishedWorkModalOpen, isUnfinishedWorkModalOpen, unfinishedWorkId, setUnfinishedWorkId }}
    >
      {children}
      <UnfinishedWorkModal
        isOpen={isUnfinishedWorkModalOpen}
        unfinishedWorkId={unfinishedWorkId}
        handleModalActionClick={handleModalActionClick}
      />
    </UnfinishedWorkModalContext.Provider>
  );
};
