"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TopBar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Button_1 = require("../button/Button");
const Styled = __importStar(require("./TopBar.styles"));
const TopBar = ({ children, gridTemplateColumns = 'auto auto 1fr auto', ...props }) => {
    return ((0, jsx_runtime_1.jsx)(Styled.TopBar, { gridTemplateColumns: gridTemplateColumns, ...props, children: children }));
};
exports.TopBar = TopBar;
const Link = ({ children, linkTo, onClick, isActive, replace }) => ((0, jsx_runtime_1.jsx)(Styled.Link, { replace: replace, to: linkTo, onClick: onClick, children: (0, jsx_runtime_1.jsx)(Styled.LinkWrapper, { variant: "h6", "$isActive": !!isActive, children: children }) }));
const ButtonWrapper = ({ children }) => (0, jsx_runtime_1.jsx)(Styled.ButtonsWrapper, { children: children });
const Button = ({ children, ...props }) => ((0, jsx_runtime_1.jsx)(exports.TopBar.ButtonsWrapper, { children: (0, jsx_runtime_1.jsx)(Button_1.Button, { variant: "contained", color: "primary", ...props, children: children }) }));
const Header = ({ isActive, children, isCentered, variant }) => ((0, jsx_runtime_1.jsx)(Styled.Header, { variant: variant || 'h6', "$isActive": !!isActive, "$isCentered": !!isCentered, children: children }));
const EmptyBarWithLine = () => ((0, jsx_runtime_1.jsx)(Styled.ButtonsWrapper, { children: (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}) }));
const Search = (props) => (0, jsx_runtime_1.jsx)(Styled.TableSearchWrapper, { children: props.children });
exports.TopBar.Link = Link;
exports.TopBar.ButtonsWrapper = ButtonWrapper;
exports.TopBar.Button = Button;
exports.TopBar.Header = Header;
exports.TopBar.TableSearchWrapper = Styled.TableSearchWrapper;
exports.TopBar.EmptyBarWithLine = EmptyBarWithLine;
exports.TopBar.Search = Search;
