import { useEffect, useState } from 'react';

import { UpdatePasswordModalContext } from '../updatePasswordModalContext/UpdatePasswordModalContext';

import { UpdatePasswordModalContextControllerrProps } from './UpdatePasswordModalContextController.types';
import { UpdatePasswordContainer } from './updatePassword/UpdatePasswordContainer';

export const IS_UPDATE_PASSWORD_REQUIRED_KEY = 'isUpdatePasswordRequired';

export const UpdatePasswordModalContextController = ({ children }: UpdatePasswordModalContextControllerrProps) => {
  const [isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen] = useState(false);

  useEffect(() => {
    const isUpdatePasswordRequired = sessionStorage.getItem(IS_UPDATE_PASSWORD_REQUIRED_KEY) === 'true';

    setIsUpdatePasswordModalOpen(isUpdatePasswordRequired);
  }, []);

  return (
    <UpdatePasswordModalContext.Provider value={{ isUpdatePasswordModalOpen, setIsUpdatePasswordModalOpen }}>
      {children}
      <UpdatePasswordContainer isModalOpen={isUpdatePasswordModalOpen} setIsModalOpen={setIsUpdatePasswordModalOpen} />
    </UpdatePasswordModalContext.Provider>
  );
};
