"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLoaderToast = void 0;
const react_toastify_1 = require("react-toastify");
const useLoaderToast = (id) => {
    const load = (text, options) => react_toastify_1.toast.loading(text, {
        autoClose: false,
        toastId: id,
        ...options,
    });
    const update = (text, options) => react_toastify_1.toast.update(id, {
        autoClose: 5000,
        render: text,
        isLoading: false,
        transition: react_toastify_1.Flip,
        closeOnClick: true,
        ...options,
    });
    const close = () => react_toastify_1.toast.dismiss(id);
    return { load, update, close };
};
exports.useLoaderToast = useLoaderToast;
