"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ACCEPTED_IMPORT_IMAGE_TYPES = exports.ACCEPTED_IMPORT_FILE_TYPES = exports.MAX_IMPORT_FILES = exports.MAX_IMPORT_FILE_SIZE_BYTES = exports.NUMBER_REGEX = exports.PHONE_REGEX = exports.JUST_LETTERS_REGEX = exports.MAX_AMOUNT_OF_SERVERS = exports.DRAWER_CLOSE_WIDTH = exports.DRAWER_OPEN_WIDTH = void 0;
exports.DRAWER_OPEN_WIDTH = 264;
exports.DRAWER_CLOSE_WIDTH = 104;
exports.MAX_AMOUNT_OF_SERVERS = 10;
exports.JUST_LETTERS_REGEX = /^[^0-9<>%$]*$/;
exports.PHONE_REGEX = new RegExp(`^([-|+[0-9]{1})?[0-9 ]*$`);
exports.NUMBER_REGEX = new RegExp(/^\d+$/);
exports.MAX_IMPORT_FILE_SIZE_BYTES = 15000000;
exports.MAX_IMPORT_FILES = 1;
exports.ACCEPTED_IMPORT_FILE_TYPES = {
    'text/plain': ['.txt'],
    'text/csv': ['.csv'],
};
exports.ACCEPTED_IMPORT_IMAGE_TYPES = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
};
