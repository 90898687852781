"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Popover = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Checkbox_1 = require("../checkbox/Checkbox");
const Styled = __importStar(require("./Popover.styles"));
const Popover = (props) => {
    return ((0, jsx_runtime_1.jsx)(Styled.Popover, { open: Boolean(props.anchorEl), anchorEl: props.anchorEl, onClose: props.onClose, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
        }, transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
        }, elevation: 0, transitionDuration: 0, PaperProps: props.PaperProps, disableScrollLock: props.disableScrollLock, children: props.type === 'filter' ? ((0, jsx_runtime_1.jsx)(Styled.List, { children: props.filterItems.map(({ name, filterBy }, index) => ((0, jsx_runtime_1.jsxs)(Styled.ListItem, { onClick: () => props.onClickFilter(filterBy), children: [props.multiSelect && ((0, jsx_runtime_1.jsx)(Checkbox_1.Checkbox, { checked: props.multiSelect.checkedItems?.includes(filterBy), sx: { p: 0, ml: 0, mr: 1.5 } })), (0, jsx_runtime_1.jsx)(Styled.ActiveText, { "$active": props.activeFilter === filterBy, children: name })] }, index))) })) : (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (0, jsx_runtime_1.jsx)(Styled.List, { children: props.children })) }));
};
exports.Popover = Popover;
