"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useModalState = void 0;
const react_1 = require("react");
const useModalState = () => {
    const [isModalOpen, setIsModalOpen] = (0, react_1.useState)(false);
    const onModalClose = () => setIsModalOpen(false);
    const onModalOpen = () => setIsModalOpen(true);
    return { isModalOpen, onModalClose, onModalOpen };
};
exports.useModalState = useModalState;
