import { useContext } from 'react';

import { UnfinishedWorkModalContext } from 'context/unfinishedWorkModal/unfinishedWorkModalContext/UnfinishedWorkModalContext';

export const useUnfinishedWorkModal = () => {
  const context = useContext(UnfinishedWorkModalContext);

  if (context === undefined) {
    throw new Error(
      'UnfinishedWorkModalContext is unavailable, make sure you are using UnfinishedWorkModalContextController',
    );
  }

  return context;
};
