"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.refreshInterceptor = void 0;
const refreshInterceptor = (api, refreshLogic, refreshFailedCallback) => async (error) => {
    //Odkomentować gdy będzie wiadomo jak ma refresh działać
    // const originalRequest = error.config as ClientRequestConfig;
    // if (error.config && (isLoginError(error.config.url) || isRefreshError(error.config.url)))
    //   return Promise.reject(error);
    // if (isAuthError(error?.response?.status) && !originalRequest._retry) {
    //   originalRequest._retry = true;
    //   try {
    //     await refreshLogic();
    //   } catch (e: unknown) {
    //     refreshFailedCallback();
    //     return;
    //   }
    //   return api(originalRequest);
    // }
    // @ts-ignore
    if (error?.response?.status === 401 && error.response.data?.message === 'Session is not active')
        refreshFailedCallback();
    return Promise.reject(error);
};
exports.refreshInterceptor = refreshInterceptor;
