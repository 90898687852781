"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutocompleteOption = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const messages_1 = require("../../../core/i18n/messages");
const useLocale_1 = require("../../../hooks/useLocale/useLocale");
const Styled = __importStar(require("./AutocompleteOption.styles"));
const AutocompleteOption = ({ idPrefix, id, label, props }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const optionBody = ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsxs)(Styled.OptionNumber, { children: [idPrefix || formatMessage({ id: messages_1.AppMessages['common.id'] }), " ", id] }), (0, jsx_runtime_1.jsx)(Styled.OptionLabel, { children: label })] }));
    if (props) {
        return (0, jsx_runtime_1.jsx)("li", { ...props, children: optionBody });
    }
    else {
        return (0, jsx_runtime_1.jsx)("span", { children: optionBody });
    }
};
exports.AutocompleteOption = AutocompleteOption;
