import { Typography } from '@mui/material';
import { MediaQuery } from 'shared-ui';
import styled from 'styled-components';

export const FormWrapper = styled.div`
  @media (min-width: ${MediaQuery.MEDIUM}px) {
    width: 50%;
  }
`;

export const Text = styled(Typography)`
  font-size: 16px;
  margin-bottom: 24px;
  min-width: 320px;
`;
