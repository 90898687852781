"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const RemoveIcon_1 = require("../../../assets/icons/RemoveIcon");
const Popover_1 = require("../../popover/Popover");
const Checkbox_1 = require("../../checkbox/Checkbox");
const useLocale_1 = require("../../../hooks/useLocale/useLocale");
const messages_1 = require("../../../core/i18n/messages");
const Styled = __importStar(require("../Table.styles"));
const HeaderCheckboxItem = ({ data, checkboxSelection }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const [headerFilterEl, setHeaderFilterEl] = (0, react_1.useState)(undefined);
    const { checked, toggleChecked, disabled } = checkboxSelection;
    const onAllCheckboxToggle = (isChecked) => {
        if (!data.length)
            return;
        if (isChecked)
            toggleChecked(data);
        else
            toggleChecked([]);
    };
    const areAllCheckboxesChecked = checked.length !== 0 && checked.length === data.length;
    return ((0, jsx_runtime_1.jsxs)(Styled.TableHeaderItem, { children: [(0, jsx_runtime_1.jsxs)(Styled.TableHeaderItemSortable, { "$isActive": !!headerFilterEl, "$disabled": !!disabled, onClick: (e) => !disabled && setHeaderFilterEl(e.currentTarget), children: [(0, jsx_runtime_1.jsx)(Styled.TableHeaderItemTextMaxWidth, { children: (0, jsx_runtime_1.jsx)(Checkbox_1.Checkbox, { checked: areAllCheckboxesChecked, disabled: disabled, disableRipple: true }) }), areAllCheckboxesChecked ? ((0, jsx_runtime_1.jsx)(Styled.HeaderCheckboxWrapper, { onClick: (e) => {
                            e.stopPropagation();
                            onAllCheckboxToggle(false);
                        }, children: (0, jsx_runtime_1.jsx)(RemoveIcon_1.RemoveIcon, {}) })) : ((0, jsx_runtime_1.jsx)(Styled.DropdownIcon, { "$disabled": !!disabled }))] }), (0, jsx_runtime_1.jsx)(Popover_1.Popover, { type: "filter", anchorEl: headerFilterEl, onClose: () => setHeaderFilterEl(undefined), filterItems: [
                    {
                        name: formatMessage({ id: messages_1.AppMessages['common.checkbox.all'] }),
                        filterBy: true,
                    },
                    {
                        name: formatMessage({ id: messages_1.AppMessages['common.checkbox.none'] }),
                        filterBy: false,
                    },
                ], onClickFilter: (filter) => {
                    onAllCheckboxToggle(filter);
                    setHeaderFilterEl(undefined);
                } })] }));
};
// eslint-disable-next-line import/no-default-export
exports.default = HeaderCheckboxItem;
