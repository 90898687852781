"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawerListItem = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_router_dom_1 = require("react-router-dom");
const useIsExactPath_1 = require("../../../hooks/useIsExactPath/useIsExactPath");
const Styled = __importStar(require("./DrawerListItem.styles"));
const DrawerListItem = ({ to, icon, activeIcon, title, open, noHighlight, onClickRoute, }) => {
    const isRouteMatch = (0, useIsExactPath_1.useIsExactPath)(to, false);
    return ((0, jsx_runtime_1.jsxs)(Styled.ListItem, { "$noHighlight": noHighlight, disableRipple: true, button: true, onClick: onClickRoute, component: react_router_dom_1.NavLink, to: to, children: [(0, jsx_runtime_1.jsx)(Styled.Icon, { "$isOpen": open, children: activeIcon ? (isRouteMatch ? activeIcon : icon) : icon }), (0, jsx_runtime_1.jsx)(Styled.TextWrapper, { "$isOpen": open, children: (0, jsx_runtime_1.jsx)(Styled.ListItemText, { "$isOpen": open, children: title }) })] }));
};
exports.DrawerListItem = DrawerListItem;
