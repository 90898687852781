"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObserverContextController = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ObserverContext_1 = require("../observerContext/ObserverContext");
const ObserverContextController = ({ children }) => {
    const tableRef = (0, react_1.useRef)(null);
    const [tableWidth, setTableWidth] = (0, react_1.useState)(undefined);
    const observer = (0, react_1.useRef)(new ResizeObserver((entries) => {
        const { width } = entries[0].contentRect;
        setTableWidth(width);
    }));
    (0, react_1.useEffect)(() => {
        if (tableRef.current) {
            observer.current.observe(tableRef.current);
        }
        return () => {
            if (tableRef.current)
                observer.current.unobserve(tableRef.current);
        };
    }, [tableRef, observer]);
    return (0, jsx_runtime_1.jsx)(ObserverContext_1.ObserverContext.Provider, { value: { tableRef, tableWidth }, children: children });
};
exports.ObserverContextController = ObserverContextController;
