"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShowUp = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const ShowUp = ({ children, show, orientation }) => {
    return ((0, jsx_runtime_1.jsx)(material_1.Collapse, { in: show, timeout: 250, orientation: orientation, children: (0, jsx_runtime_1.jsx)(material_1.Fade, { in: show, timeout: {
                enter: 800,
                exit: 0,
            }, appear: false, children: (0, jsx_runtime_1.jsx)("div", { children: children }) }) }));
};
exports.ShowUp = ShowUp;
