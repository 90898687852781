"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PinCodeInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const ToggleValueInput_1 = require("../toggleValueInput/ToggleValueInput");
exports.PinCodeInput = (0, react_1.forwardRef)((props, ref) => {
    const onKeyDown = (e) => {
        const numberRegex = /^\d+$/;
        if (numberRegex.test(e.key) || e.key === 'Backspace' || e.key === 'Tab') {
            return;
        }
        e.preventDefault();
    };
    return (0, jsx_runtime_1.jsx)(ToggleValueInput_1.ToggleValueInput, { ...props, ref: ref, onKeyDown: onKeyDown, inputProps: { maxLength: 4 } });
});
