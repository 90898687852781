"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const SortIcon = ({ isSorted }) => {
    if (isSorted) {
        return ((0, jsx_runtime_1.jsx)("svg", { xmlns: "http://www.w3.org/2000/svg", width: "25", height: "25", viewBox: "0 0 25 25", children: (0, jsx_runtime_1.jsx)("g", { fill: "none", fillRule: "evenodd", children: (0, jsx_runtime_1.jsx)("g", { children: (0, jsx_runtime_1.jsx)("g", { children: (0, jsx_runtime_1.jsxs)("g", { transform: "translate(-902.000000, -254.000000) translate(144.000000, 248.000000) translate(758.544304, 6.823529)", children: [(0, jsx_runtime_1.jsx)("rect", { width: "24", height: "24", fill: "#F9F9F9", rx: "6" }), (0, jsx_runtime_1.jsx)("path", { fill: "#495171", d: "M12 7c.552 0 1 .448 1 1v7.538l2.299-2.271c.39-.418 1.021-.418 1.41.002.194.209.292.483.292.757 0 .23-.068.458-.204.65l-.09.11-2.3 2.274c-.627.674-1.465.985-2.287.935-.04.003-.08.005-.12.005-.04 0-.08-.002-.12-.007l-.078.005c-.797.027-1.601-.286-2.208-.938l-2.301-2.274-.09-.11c-.135-.192-.203-.42-.203-.65 0-.274.097-.548.291-.757.39-.42 1.02-.42 1.41-.002L11 15.538V8c0-.552.448-1 1-1z" })] }) }) }) }) }));
    }
    else {
        return ((0, jsx_runtime_1.jsx)("svg", { xmlns: "http://www.w3.org/2000/svg", width: "25", height: "24", viewBox: "0 0 25 24", children: (0, jsx_runtime_1.jsx)("g", { fill: "none", fillRule: "evenodd", children: (0, jsx_runtime_1.jsx)("g", { children: (0, jsx_runtime_1.jsx)("g", { children: (0, jsx_runtime_1.jsxs)("g", { transform: "translate(-276.000000, -256.000000) translate(144.000000, 248.000000) translate(132.544304, 8.000000)", children: [(0, jsx_runtime_1.jsx)("rect", { width: "24", height: "24", fill: "#F9F9F9", rx: "6" }), (0, jsx_runtime_1.jsx)("path", { fill: "#495171", d: "M12 7c.552 0 1 .448 1 1v7.538l2.299-2.271c.39-.418 1.021-.418 1.41.002.194.209.292.483.292.757 0 .23-.068.458-.204.65l-.09.11-2.3 2.274c-.627.674-1.465.985-2.287.935-.04.003-.08.005-.12.005-.04 0-.08-.002-.12-.007l-.078.005c-.797.027-1.601-.286-2.208-.938l-2.301-2.274-.09-.11c-.135-.192-.203-.42-.203-.65 0-.274.097-.548.291-.757.39-.42 1.02-.42 1.41-.002L11 15.538V8c0-.552.448-1 1-1z", transform: "translate(12.000290, 12.500000) scale(1, -1) translate(-12.000290, -12.500000)" })] }) }) }) }) }));
    }
};
exports.SortIcon = SortIcon;
