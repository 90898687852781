import { ErrorBoundary, Profile, useQuery } from 'shared-ui';

import { getAppVersion } from 'api/auth/auth';
import { QueryKeyEnum } from 'core/global.enum';
import { changeUserPasswordWithConfirmation } from 'api/users/users';

export const ProfileContainer = () => {
  const { data: appVersionResponse } = useQuery([QueryKeyEnum.APP_VERSION], getAppVersion);

  return (
    <ErrorBoundary>
      <Profile
        appVersion={appVersionResponse?.version || ''}
        changePasswordMutation={changeUserPasswordWithConfirmation}
      />
    </ErrorBoundary>
  );
};
