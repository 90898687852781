"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangePasswordContainer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_toastify_1 = require("react-toastify");
const react_hook_form_1 = require("react-hook-form");
const yup_1 = require("@hookform/resolvers/yup");
const useLocale_1 = require("../../hooks/useLocale/useLocale");
const useMutation_1 = require("../../hooks/useMutation/useMutation");
const useAuth_1 = require("../../hooks/useAuth/useAuth");
const messages_1 = require("../../core/i18n/messages");
const useModalState_1 = require("../../hooks/useModalState/useModalState");
const useErrorHandler_1 = require("../../hooks/useErrorHandler/useErrorHandler");
const ChangePassword_1 = require("./ChangePassword");
const ChangePassword_validation_1 = require("./ChangePassword.validation");
const ChangePasswordContainer = ({ changePasswordMutation }) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const { user } = (0, useAuth_1.useAuth)();
    const { isModalOpen, onModalOpen, onModalClose } = (0, useModalState_1.useModalState)();
    const { handleError } = (0, useErrorHandler_1.useErrorHandler)();
    const { mutate: onChangeUserPassword, isLoading } = (0, useMutation_1.useMutation)(changePasswordMutation, {
        onSuccess: () => {
            react_toastify_1.toast.dark(formatMessage({ id: messages_1.AppMessages['changePassword.success'] }));
            onModalClose();
        },
        onError: (error) => {
            const errorFields = error.response?.data.fields;
            const isWrongOldPasswordError = errorFields?.find(({ type, field }) => type === 'wrong_password' && field === 'oldPassword');
            if (isWrongOldPasswordError) {
                return formMethods.setError('oldPassword', {
                    message: formatMessage({ id: messages_1.AppMessages['changePassword.validation.oldPassword.wrong'] }),
                });
            }
            handleError({ error, omitAPIErrorMessage: true });
        },
    });
    const { schema } = (0, ChangePassword_validation_1.useChangePasswordValidation)();
    const formMethods = (0, react_hook_form_1.useForm)({
        resolver: (0, yup_1.yupResolver)(schema),
        mode: 'all',
    });
    const onSubmit = (body) => {
        if (typeof user === 'object' && 'username' in user) {
            onChangeUserPassword({ username: user.username, body });
            return;
        }
        if (typeof user === 'object' && 'email' in user) {
            onChangeUserPassword({ username: user.email, body });
            return;
        }
    };
    (0, react_1.useEffect)(() => {
        formMethods.reset();
    }, [isModalOpen]);
    return ((0, jsx_runtime_1.jsx)(react_hook_form_1.FormProvider, { ...formMethods, children: (0, jsx_runtime_1.jsx)(ChangePassword_1.ChangePassword, { isModalOpen: isModalOpen, onModalClose: onModalClose, onModalOpen: onModalOpen, isLoading: isLoading, onSubmit: onSubmit }) }));
};
exports.ChangePasswordContainer = ChangePasswordContainer;
