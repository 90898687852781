"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Datepicker = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const x_date_pickers_1 = require("@mui/x-date-pickers");
const useLocale_1 = require("../../hooks/useLocale/useLocale");
const global_enum_1 = require("../../core/global.enum");
const messages_1 = require("../../core/i18n/messages");
const DatepickerInput_1 = require("./datepickerInput/DatepickerInput");
const DatepickerPopover_1 = require("./datepickerPopover/DatepickerPopover");
const Styled = __importStar(require("./Datepicker.styles"));
exports.Datepicker = (0, react_1.forwardRef)(({ withCalendarIcon, inputFormat, placeholderText, label, required, isClickable, value, withDatepickerPopover, errorMessage, withoutHelperText, onIconClick, onChange, smallPlaceholderFontSize, mask = '____/__/__ __:__:__', withoutErrorMessage, ...rest }, ref) => {
    const { formatMessage } = (0, useLocale_1.useLocale)();
    const [isOpen, setIsOpen] = (0, react_1.useState)(false);
    const [datepickerEl, setDatepickerEl] = (0, react_1.useState)(undefined);
    const toogleDatepicker = () => {
        withDatepickerPopover && setIsOpen(!isOpen);
    };
    return ((0, jsx_runtime_1.jsxs)(Styled.DatepickerWrapper, { children: [label && ((0, jsx_runtime_1.jsxs)(Styled.Label, { htmlFor: label, children: [label, " ", required && (0, jsx_runtime_1.jsx)(Styled.Required, { children: "*" })] })), (0, jsx_runtime_1.jsx)(x_date_pickers_1.DatePicker, { ...rest, onChange: (date) => (date ? onChange(date) : onChange('')), mask: mask, value: value || '', inputFormat: inputFormat || global_enum_1.DateFormat.FULL, open: false, InputProps: { ...rest.InputProps, id: rest.id || label }, renderInput: (params) => ((0, jsx_runtime_1.jsx)(DatepickerInput_1.DatepickerInput, { ...params, id: label, error: value ? params.error : false, inputProps: {
                        ...params.inputProps,
                        value: value ? params?.inputProps?.value : '',
                        autoComplete: 'new-password',
                        form: {
                            autoComplete: 'off',
                        },
                    }, ref: ref, setDatepickerEl: setDatepickerEl, placeholder: placeholderText || formatMessage({ id: messages_1.AppMessages['common.datePlaceholder'] }), toogleDatepicker: toogleDatepicker, withCalendarIcon: withCalendarIcon, isClickable: isClickable, errorMessage: errorMessage, withoutHelperText: withoutHelperText, label: "", onIconClick: onIconClick, smallPlaceholderFontSize: smallPlaceholderFontSize, withoutErrorMessage: withoutErrorMessage })) }), (0, jsx_runtime_1.jsx)(DatepickerPopover_1.DatepickerPopover, { onChange: onChange, value: value, toogleDatepicker: toogleDatepicker, isOpen: isOpen, datepickerEl: datepickerEl, ...rest })] }));
});
