"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropzoneErrorCodes = void 0;
var DropzoneErrorCodes;
(function (DropzoneErrorCodes) {
    DropzoneErrorCodes["INVALID_RESOLUTION"] = "invalid-resolution";
    DropzoneErrorCodes["FILE_INVALID_TYPE"] = "file-invalid-type";
    DropzoneErrorCodes["FILE_TOO_LARGE"] = "file-too-large";
    DropzoneErrorCodes["TOO_MANY_FILES"] = "too-many-files";
})(DropzoneErrorCodes || (exports.DropzoneErrorCodes = DropzoneErrorCodes = {}));
