"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDropzoneHelpers = void 0;
const Dropzone_types_1 = require("../Dropzone.types");
const DEFAULT_MAX_PHOTO_HEIGHT = 1200;
const DEFAULT_MAX_PHOTO_WIDTH = 1600;
const isDragEvent = (event) => {
    return event.target !== undefined;
};
const useDropzoneHelpers = () => {
    const getFilesWithResolution = async (event) => {
        let files = [];
        if (isDragEvent(event)) {
            files = event.target.files || event.dataTransfer?.files || [];
        }
        else {
            files = await Promise.all(event.map(async (handle) => await handle.getFile()));
        }
        const filesArray = Array.from(files);
        const isFileNotImageType = filesArray.some((file) => !file.type.includes('image'));
        if (isFileNotImageType)
            return await Promise.all(filesArray);
        if (!filesArray.length)
            return Promise.all([]);
        const promises = filesArray.map(async (file) => {
            const image = new Image();
            const fileWithResolution = file;
            const promise = new Promise((resolve) => {
                image.onload = () => {
                    fileWithResolution.width = image.width;
                    fileWithResolution.height = image.height;
                    resolve(fileWithResolution);
                };
                const url = URL.createObjectURL(fileWithResolution);
                image.src = url;
            });
            return promise;
        });
        return await Promise.all(promises);
    };
    const customImageRsolutionValidation = (file, customPhotoMaxWidth, customPhotoMaxHeight) => {
        const fileMaxWidth = customPhotoMaxWidth ?? DEFAULT_MAX_PHOTO_WIDTH;
        const fileMaxHeight = customPhotoMaxHeight ?? DEFAULT_MAX_PHOTO_HEIGHT;
        if ((file.width > fileMaxWidth && file.width > fileMaxHeight) ||
            (file.height > fileMaxWidth && file.height > fileMaxHeight)) {
            return {
                code: Dropzone_types_1.DropzoneErrorCodes.INVALID_RESOLUTION,
                message: 'Invalid resolution',
            };
        }
        else {
            return null;
        }
    };
    const customFileValidation = (file) => {
        if (!file.name)
            return null;
        const fileNameDotts = file.name.match(/\./g);
        if (fileNameDotts && fileNameDotts.length > 1) {
            return {
                code: Dropzone_types_1.DropzoneErrorCodes.FILE_INVALID_TYPE,
                message: 'Invalid file type',
            };
        }
        else {
            return null;
        }
    };
    return { getFilesWithResolution, customImageRsolutionValidation, customFileValidation };
};
exports.useDropzoneHelpers = useDropzoneHelpers;
