"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePagination = void 0;
const react_1 = require("react");
const usePagination = () => {
    const [page, setPage] = (0, react_1.useState)(0);
    const [rowsPerPage, setRowsPerPage] = (0, react_1.useState)(25);
    return { page, setPage, rowsPerPage, setRowsPerPage };
};
exports.usePagination = usePagination;
