"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmptyDroponeWrapper = exports.RemoveButton = exports.Slider = exports.SlderWrapper = exports.FilesValidationText = exports.ValidationWrapper = exports.UploadTextButton = exports.UploadTextName = exports.UploadSubText = exports.UploadText = exports.UploadTextWrapper = exports.Dropzone = exports.DropzoneWrapper = void 0;
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
exports.DropzoneWrapper = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
exports.Dropzone = styled_components_1.default.div(({ $isDragActive, $isError, $width, $height }) => (0, styled_components_1.css) `
    position: relative;
    margin-bottom: 8px;
    width: ${$width}px;
    height: ${$height}px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 16px;
    background-color: ${$isDragActive ? '#eaf1ff' : $isError ? '#fef5f3' : 'transparent'};
    background-image: ${$isDragActive
    ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%232D6CE6FF' stroke-width='4' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`
    : $isError
        ? undefined
        : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23979797FF' stroke-width='4' stroke-dasharray='8%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");`};
  `);
exports.UploadTextWrapper = styled_components_1.default.div `
  margin-left: 10px;
  text-align: center;
`;
exports.UploadText = (0, styled_components_1.default)(material_1.Typography) `
  font-weight: 700;
`;
exports.UploadSubText = (0, styled_components_1.default)(material_1.Typography) `
  font-size: 12px;
  font-weight: 800;
`;
exports.UploadTextName = (0, styled_components_1.default)(material_1.Typography) `
  font-weight: 700;
  margin-bottom: 3px;
`;
exports.UploadTextButton = (0, styled_components_1.default)(material_1.Typography)(({ theme: { palette } }) => (0, styled_components_1.css) `
    font-weight: 700;
    color: ${palette.primary.main};
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 34px;
  `);
exports.ValidationWrapper = styled_components_1.default.div `
  min-height: 42px;
`;
exports.FilesValidationText = (0, styled_components_1.default)(material_1.Typography)(({ $errorAlignedLeft, $width, theme: { palette } }) => (0, styled_components_1.css) `
    color: ${palette.warning.main};
    width: ${$width}px;
    margin-top: 5px;
    text-align: ${$errorAlignedLeft ? 'left' : 'center'};
  `);
exports.SlderWrapper = styled_components_1.default.div(({ theme: { palette } }) => (0, styled_components_1.css) `
    width: 284px;
    min-height: 42px;
    background-color: ${palette.grey[900]};
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  `);
exports.Slider = (0, styled_components_1.default)(material_1.Slider)(({ theme: { palette } }) => (0, styled_components_1.css) `
    color: ${palette.grey[500]};
    margin: 0 8px;
  `);
exports.RemoveButton = (0, styled_components_1.default)(material_1.Button)(({ theme: { palette } }) => (0, styled_components_1.css) `
    position: absolute;
    top: 12px;
    right: 12px;
    width: 48px;
    min-width: 48px;
    height: 48px;
    background-color: ${palette.grey[900]};
    padding: 0;
  `);
exports.EmptyDroponeWrapper = styled_components_1.default.div `
  margin-bottom: 40px;
`;
