"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DesktopDrawer = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const useDrawer_1 = require("../../../hooks/useDrawer/useDrawer");
const DrawerArrowIcon_1 = require("../../../assets/icons/DrawerArrowIcon");
const ProfileIcon_1 = require("../../../assets/icons/ProfileIcon");
const DrawerListItem_1 = require("../drawerListItem/DrawerListItem");
const TextEllipsisWithTooltip_1 = require("../../textEllipsis/TextEllipsisWithTooltip");
const Styled = __importStar(require("./DesktopDrawer.styles"));
const RootStyled = __importStar(require("../DrawerLayout.styles"));
const MAX_USERNAME_WIDTH = 170;
const DesktopDrawer = ({ drawerRoutes, logo, profileRoute, onClickRoute }) => {
    const { isDrawerOpen, setIsDrawerOpen } = (0, useDrawer_1.useDrawer)();
    return ((0, jsx_runtime_1.jsxs)(Styled.Drawer, { "$isDrawerOpen": isDrawerOpen, variant: "permanent", children: [(0, jsx_runtime_1.jsx)(Styled.OpenDrawerIcon, { "$isDrawerOpen": isDrawerOpen, onClick: () => setIsDrawerOpen(!isDrawerOpen), children: (0, jsx_runtime_1.jsx)(DrawerArrowIcon_1.DrawerArrowIcon, { isDrawerOpen: isDrawerOpen }) }), (0, jsx_runtime_1.jsx)(Styled.LogoContainer, { "$isDrawerOpen": isDrawerOpen, children: logo }), (0, jsx_runtime_1.jsxs)(Styled.List, { children: [(0, jsx_runtime_1.jsx)("div", { children: drawerRoutes.map((route) => route.isVisible ? ((0, jsx_runtime_1.jsx)(DrawerListItem_1.DrawerListItem, { icon: route.icon, activeIcon: route.activeIcon, to: route.to, title: route.title, open: isDrawerOpen, onClickRoute: onClickRoute }, route.to)) : null) }), profileRoute && ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(DrawerListItem_1.DrawerListItem, { icon: (0, jsx_runtime_1.jsx)(RootStyled.ProfileIconCircle, { children: (0, jsx_runtime_1.jsx)(ProfileIcon_1.ProfileIcon, {}) }), to: profileRoute.to, title: (0, jsx_runtime_1.jsx)(TextEllipsisWithTooltip_1.TextElipsisWithTooltip, { text: profileRoute.title, width: MAX_USERNAME_WIDTH }), open: isDrawerOpen, onClickRoute: onClickRoute, noHighlight: true }) }))] })] }));
};
exports.DesktopDrawer = DesktopDrawer;
