"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RowItem = exports.TagItem = void 0;
const material_1 = require("@mui/material");
const styled_components_1 = __importStar(require("styled-components"));
const tagColor = (tag, isIndividualPermissionTag) => ({
    PRZYZNANO: isIndividualPermissionTag ? '#c8ebca' : '#f9f9f9',
    BRAK: isIndividualPermissionTag ? '#ffe6e6' : '#f9f9f9',
    NIEZDEFINIOWANO: '#f9f9f9',
    BRAK_INDYWIDUALNE: '#ffe6e6',
    PRZYZNANO_INDYWIDUALNE: '#c8ebca',
    STATYCZNA: '#dbe8ff',
    DYNAMICZNA: '#e0e3a2',
    ADMINISTRATOR: '#dbe8ff',
    ZWYKLY: '#e0e3a2',
}[tag]);
exports.TagItem = (0, styled_components_1.default)(material_1.Typography)(({ $tag, $isIndividualPermissionTag }) => (0, styled_components_1.css) `
    background-color: ${tagColor($tag, $isIndividualPermissionTag)};
    padding: 6px 16px;
    font-weight: 600 !important;
    margin-right: 9px;
    border-radius: 16px;
    display: inline-block;
    max-width: 170px;
    line-height: 1;
  `);
exports.RowItem = styled_components_1.default.span `
  padding: 0 17px;
`;
