"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrawerContextController = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const DrawerContext_1 = require("../drawerContext/DrawerContext");
const DrawerContextController = ({ children }) => {
    const [isDrawerOpen, setIsDrawerOpen] = (0, react_1.useState)(false);
    return (0, jsx_runtime_1.jsx)(DrawerContext_1.DrawerContext.Provider, { value: { isDrawerOpen, setIsDrawerOpen }, children: children });
};
exports.DrawerContextController = DrawerContextController;
