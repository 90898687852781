import { Controller, useFormContext } from 'react-hook-form';
import { useLocale, Modal, ToggleValueInput } from 'shared-ui';

import { AppMessages } from 'i18n/messages';

import * as Styled from './UpdatePassword.styles';
import { UpdatePasswordProps, UpdatePasswordForm } from './UpdatePassword.types';

export const UpdatePassword = ({ onSubmit, isModalOpen, isLoading }: UpdatePasswordProps) => {
  const { formatMessage } = useLocale();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useFormContext<UpdatePasswordForm>();

  return (
    <>
      <Modal
        header={formatMessage({ id: AppMessages['updatePassword.title'] })}
        open={isModalOpen}
        size="medium"
        type="form"
        onSubmit={handleSubmit(onSubmit)}
        submitButtonDisabled={isLoading || !isValid}
        submitButtonText={formatMessage({ id: AppMessages['updatePassword.actionButton'] })}
      >
        <Styled.Text>{formatMessage({ id: AppMessages['updatePassword.description'] })}</Styled.Text>
        <Styled.FormWrapper>
          <Controller
            name="newPassword"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <ToggleValueInput
                {...field}
                errorMessage={errors.newPassword?.message}
                type="password"
                label={formatMessage({ id: AppMessages['updatePassword.password.label'] })}
                placeholder={formatMessage({
                  id: AppMessages['updatePassword.password.placeholder'],
                })}
                required
              />
            )}
          />

          <Controller
            name="repeatPassword"
            defaultValue=""
            control={control}
            render={({ field }) => (
              <ToggleValueInput
                {...field}
                errorMessage={errors.repeatPassword?.message}
                type="password"
                label={formatMessage({ id: AppMessages['updatePassword.confirmPassword.label'] })}
                placeholder={formatMessage({
                  id: AppMessages['updatePassword.confirmPassword.placeholder'],
                })}
                required
              />
            )}
          />
        </Styled.FormWrapper>
      </Modal>
    </>
  );
};
