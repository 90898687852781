import { endOfMonth, isSameMonth, startOfMonth, subMonths } from 'date-fns';
import { useLocale } from 'shared-ui';

import { AppMessages } from 'i18n/messages';

import { RemoteWorkFiltersProps } from './RemoteWorkFilters.types';
import * as Styled from './RemoteWorkFilters.styles';

export const RemoteWorkFilters = ({ filters, setFilters }: RemoteWorkFiltersProps) => {
  const { formatMessage } = useLocale();

  const isCurrentMonthSelected = filters.dataOd && isSameMonth(filters.dataOd, new Date());
  const isPreviousMonthSelected = filters.dataOd && isSameMonth(filters.dataOd, subMonths(new Date(), 1));

  return (
    <>
      <Styled.FilterButton
        variant={isCurrentMonthSelected ? 'contained' : 'outlined'}
        onClick={() =>
          setFilters((prevFilters) => ({
            ...prevFilters,
            dataOd: startOfMonth(new Date()),
            dataDo: endOfMonth(new Date()),
          }))
        }
      >
        {formatMessage({ id: AppMessages['remoteWork.filter.currentMonth'] })}
      </Styled.FilterButton>
      <Styled.FilterButton
        variant={isPreviousMonthSelected ? 'contained' : 'outlined'}
        onClick={() =>
          setFilters((prevFilters) => ({
            ...prevFilters,
            dataOd: startOfMonth(subMonths(new Date(), 1)),
            dataDo: endOfMonth(subMonths(new Date(), 1)),
          }))
        }
      >
        {formatMessage({ id: AppMessages['remoteWork.filter.previousMonth'] })}
      </Styled.FilterButton>
    </>
  );
};
