"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileMobileIcon = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ProfileMobileIcon = ({ active }) => {
    if (active) {
        return ((0, jsx_runtime_1.jsx)("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.8862 12.5705C17.5636 12.2115 17.0147 12.1869 16.6633 12.5164C16.3118 12.8459 16.2877 13.4066 16.6103 13.7656C17.6744 14.9508 18.2618 16.4902 18.2618 18.0984V21.9C18.2618 22.077 18.1174 22.2246 17.944 22.2246H6.04634C5.87301 22.2246 5.72856 22.077 5.72856 21.9V18.0984C5.72856 14.5672 8.54048 11.7 11.9928 11.7C15.1514 11.7 17.7225 9.07377 17.7225 5.84754C17.7225 2.62131 15.1562 0 11.9976 0C8.839 0 6.26783 2.62623 6.26783 5.85246C6.26783 6.81639 6.50376 7.77541 6.94673 8.62131C7.17304 9.0541 7.69786 9.21148 8.12158 8.98033C8.54529 8.74918 8.69937 8.21311 8.47307 7.78033C8.16491 7.19016 8.0012 6.52623 8.0012 5.85246C8.0012 3.6 9.79236 1.77049 11.9976 1.77049C14.2028 1.77049 15.994 3.6 15.994 5.85246C15.994 8.10492 14.2028 9.93443 11.9976 9.93443C7.58712 9.93443 4 13.5984 4 18.1033V21.9049C4 23.0607 4.91965 24 6.05116 24H17.9488C19.0803 24 20 23.0607 20 21.9049V18.1033C20 16.0475 19.2489 14.0852 17.8862 12.5705Z", fill: "white" }) }));
    }
    else {
        return ((0, jsx_runtime_1.jsx)("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", children: (0, jsx_runtime_1.jsx)("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M17.8862 12.5705C17.5636 12.2115 17.0147 12.1869 16.6633 12.5164C16.3118 12.8459 16.2877 13.4066 16.6103 13.7656C17.6744 14.9508 18.2618 16.4902 18.2618 18.0984V21.9C18.2618 22.077 18.1174 22.2246 17.944 22.2246H6.04634C5.87301 22.2246 5.72856 22.077 5.72856 21.9V18.0984C5.72856 14.5672 8.54048 11.7 11.9928 11.7C15.1514 11.7 17.7225 9.07377 17.7225 5.84754C17.7225 2.62131 15.1562 0 11.9976 0C8.839 0 6.26783 2.62623 6.26783 5.85246C6.26783 6.81639 6.50376 7.77541 6.94673 8.62131C7.17304 9.0541 7.69786 9.21148 8.12158 8.98033C8.54529 8.74918 8.69937 8.21311 8.47307 7.78033C8.16491 7.19016 8.0012 6.52623 8.0012 5.85246C8.0012 3.6 9.79236 1.77049 11.9976 1.77049C14.2028 1.77049 15.994 3.6 15.994 5.85246C15.994 8.10492 14.2028 9.93443 11.9976 9.93443C7.58712 9.93443 4 13.5984 4 18.1033V21.9049C4 23.0607 4.91965 24 6.05116 24H17.9488C19.0803 24 20 23.0607 20 21.9049V18.1033C20 16.0475 19.2489 14.0852 17.8862 12.5705Z", fill: "#A4AAC4" }) }));
    }
};
exports.ProfileMobileIcon = ProfileMobileIcon;
