import { PageLayout, useLocale } from 'shared-ui';

import { AppMessages } from 'i18n/messages';
import { WebsocketContextController } from 'context/websocket/websocketContextController/WebsocketContextController';

import { RemoteWorkTimer } from './remoteWorkTimer/RemoteWorkTimer';
import { RemoteWorkTableContainer } from './remoteWorkTable/RemoteWorkTableContainer';
import * as Styled from './RemoteWork.styles';

export const RemoteWork = () => {
  const { formatMessage } = useLocale();

  return (
    <WebsocketContextController>
      <PageLayout
        header={formatMessage({ id: AppMessages['remoteWork.title'] })}
        renderContent={() => (
          <>
            <Styled.Line />

            <RemoteWorkTimer />

            <RemoteWorkTableContainer />
          </>
        )}
      />
    </WebsocketContextController>
  );
};
